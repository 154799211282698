import {observable} from 'mobx';

import Model from './Model';
import Collection from './Collection';

export class DiscussionModel extends Model {
    @observable subscribed;
    static schema = {
        title: {type: String},
        content: {type: String},
        universe_id: {type: Number},
    };

    get url() {
        if (this.id) {
            return `/api/discussions/${this.id}`;
        }
        return '/api/discussions';
    }
    getViewUrl(context) {
        return `/${context.currentLanguage}/universe/${this.attrs.universe_id}/discussions/${this.id}`;
    }
    checkSubscribed() {
        this.api.get(`${this.url}/subscribe`).then((resp)=> {
            this.subscribed = Boolean(resp);
        });
    }
    toggleSubscribed(value) {
        if (this.subscribed == value) {
            return Promise.resolve(this);
        }
        if (this.subscribed) {
            return this.api.delete(`${this.url}/subscribe`).then(()=> {
                this.subscribed = false;
                this.attrs.subscriber_count--;
            }).catch(console.error);
        } else {
            return this.api.post(`${this.url}/subscribe`).then(()=> {
                this.subscribed = true;
                this.attrs.subscriber_count++;
            }).catch(console.error);
        }
    }
}

export class DiscussionCollection extends Collection {
    static modelClass = DiscussionModel;
    url = '/api/discussions';

    getListUrl(context, universe_id) {
        if (universe_id) {
            return `/${context.currentLanguage}/universe/${universe_id}/discussions`;
        }
    }
    getCreateUrl(context, universe_id) {
        return `${this.getListUrl(context, universe_id)}/new`;
    }
}
