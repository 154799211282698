import React from 'react';

var TocItem = ({item})=> {
    return <a href={`#${item.slug}`} onClick={(evt)=> {
        evt.preventDefault();
        var target = document.getElementById(item.slug);
        target && target.scrollIntoView({behavior: 'smooth'});

        if(history.pushState) {
            history.pushState(null, null, `#${item.slug}`);
        } else {
            location.hash = `#${item.slug}`;
        }
    }}>{item.label}</a>;
};
var TocIndex = ({toc})=> {
    return toc ? <ol>
        {toc.map((item, index)=> {
            return <li key={index}>
                {item.label &&
                    <TocItem item={item} />
                }
                {item.children && item.children.length > 0 &&
                    <TocIndex toc={item.children} />
                }
            </li>;
        })}
    </ol> : null;
};
export var TableOfContents = ({toc, title})=> {
    return <nav role="navigation">
        <TocItem item={{
            slug: 'top',
            label: title
        }} />
        <TocIndex toc={toc} />
    </nav>;
};
