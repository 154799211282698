
import Model from './Model';
import Collection from './Collection';

export class PictureModel extends Model {
    static schema = {
        file: {type: 'File'},
        url: {type: String}
    };

    get url() {
        if (this.id) {
            return `/api/pictures/${this.id}`;
        }
        return '/api/pictures';
    }
    create() {
        this.state = 'request';
        return this.api.post(this.url, this.json(), 'multipart/form-data').then((json)=> {
            return this.parse(json);
        }).catch((err)=> {
            return this.handleError(err);
        });
    }
}

export class PictureCollection extends Collection {
    static modelClass = PictureModel;
    url = '/api/pictures';
}
