

export class RestApiBackend {
    constructor(context) {
        this.context = context;
    }

    post(url, data, format) {
        return this.request(url, 'POST', data, format);
    }

    put(url, data) {
        return this.request(url, 'PUT', data);
    }

    patch(url, data, format) {
        return this.request(url, 'PATCH', data, format);
    }

    request(url, method, data, format='json') {
        var options = {
            method: method,
            headers: {},
        };
        var authorization;

        if (this.context.auth.attrs.token) {
            authorization = `Token ${this.context.auth.attrs.token}`;
        }

        if (data && format == 'json') {
            options.body = JSON.stringify(data);
            options.headers['Content-Type'] = 'application/json';
        } else if (data) {
            var form = new FormData();
            for (var key in data) {
                form.append(key, data[key]);
            }
            options.body = form;
        }

        if (authorization) {
            options.headers.Authorization = authorization;
        }

        return fetch(this.context.backendUrl + url, options).then((response)=> {
            return this.handleResponse(response);
        });
    }

    get(url, query) {
        if (query) {
            url = `${url}?${this.params(query)}`;
        }
        return this.request(url, 'GET');
    }

    delete(url) {
        return this.request(url, 'DELETE');
    }

    handleResponse(response) {
        if (response.ok) {
            if (response.status == 200) {
                return response.json().then(function(json) {
                    return json;
                });
            } else {
                return Promise.resolve();
            }
        } else {
            var error = new Error(response.statusText);
            error.response = response;
            error.status = response.status;
            var contentType = response.headers.get('Content-Type');

            if (response.status === 400 && contentType && contentType.indexOf('json') >= 0) {
                return response.json().then(function(json) {
                    error.json = json;
                    throw error;
                });
            } else {
                throw error;
            }
        }
    }

    params(params) {
        return Object.entries(params)
            .map(([k, v]) => {
                if (Array.isArray(v)) {
                    return v.map((i)=> `${encodeURIComponent(k)}[]=${encodeURIComponent(i)}`).join('&');
                } else {
                    return `${encodeURIComponent(k)}=${encodeURIComponent(v)}`;
                }
            })
            .join('&');
    }
}
