import React from 'react';
import {observer} from 'mobx-react';
import {Link} from 'react-router-dom';

import {gettext} from '../i18n';
import {linkValue, linkSelected} from './helpers';


@observer class Login extends React.Component {
    render() {
        return <div>
            <div className="form-group">
                <input
                    name="email"
                    type="email"
                    required
                    className={`form-control ${this.props.model.errorClass('email')}`}
                    placeholder={gettext('Email')}
                    {...linkValue('email', this.props.model.attrs)} />
                {this.props.model.errorMessage('email')}
            </div>
            <div className="form-group">
                <input
                    name="password"
                    type="password"
                    placeholder={gettext('Password')}
                    minLength={5}
                    required
                    className={`form-control ${this.props.model.errorClass('password')}`}
                    {...linkValue('password', this.props.model.attrs)}/>
                {this.props.model.errorMessage('password')}
            </div>
            {this.props.model.errorMessage()}
            <div className="form-group">
                <button type="submit" className="btn btn-secondary btn-block">{gettext('Sign in')}</button>
            </div>
        </div>;
    }
}
@observer class Register extends React.Component {
    render() {
        return <div>
            <div className="form-group">
                <input
                    name="name"
                    placeholder={gettext('Name')}
                    required
                    className={`form-control ${this.props.model.errorClass('name')}`}
                    {...linkValue('name', this.props.model.attrs)} />
                {this.props.model.errorMessage('name')}
            </div>
            <div className="form-group">
                <input
                    name="email"
                    type="email"
                    required
                    className={`form-control ${this.props.model.errorClass('email')}`}
                    placeholder={gettext('Email')}
                    {...linkValue('email', this.props.model.attrs)} />
                {this.props.model.errorMessage('email')}
            </div>
            <div className="form-group">
                <input
                    name="password"
                    type="password"
                    placeholder={gettext('Password')}
                    minLength={5}
                    required
                    className={`form-control ${this.props.model.errorClass('password')}`}
                    {...linkValue('password', this.props.model.attrs)}/>
                {this.props.model.errorMessage('password')}
            </div>
            {this.props.model.errorMessage()}
            <div className="form-group">
                <button type="submit" className="btn btn-primary btn-block">{gettext('Register')}</button>
            </div>
            <Link to={`/${this.props.context.currentLanguage}/about`} onClick={()=> {
                this.props.auth.loginAction = null;
            }}>
                {gettext('About Multiversalis')}
            </Link>
        </div>;
    }
}
@observer class ForgotPassword extends React.Component {
    render() {
        return <div>
            <div className="form-group">
                <input
                    name="email"
                    type="email"
                    required
                    className={`form-control ${this.props.model.errorClass('email')}`}
                    placeholder={gettext('Email')}
                    {...linkValue('email', this.props.model.attrs)} />
                {this.props.model.errorMessage('email')}
            </div>
            {this.props.model.errorMessage()}
            <div className="form-group">
                <button type="submit" className="btn btn-warning btn-block">{gettext('Reset password')}</button>
            </div>
        </div>;
    }
}

@observer export class AuthForm extends React.Component {

    render() {
        return <div className="auth-card floating-card card-arrow-top card">
            <form onSubmit={this.doAction.bind(this)} className={`card-body ${this.formState}`}>
                <div className="form-group">
                    <div><label>
                        <input type="radio" {...linkSelected('loginAction', this.props.auth, 'login')} />
                        {' '}{gettext('Login')}
                    </label></div>
                    <div><label>
                        <input type="radio" {...linkSelected('loginAction', this.props.auth, 'register')} />
                        {' '}{gettext('New account')}
                    </label></div>
                    <div><label>
                        <input type="radio" {...linkSelected('loginAction', this.props.auth, 'forgot-password')} />
                        {' '}{gettext('I forgot my password')}
                    </label></div>
                </div>
                {this.props.auth.loginAction == 'register' &&
                    <Register
                        auth={this.props.auth}
                        context={this.props.context}
                        model={this.props.auth.registerForm} />
                }
                {this.props.auth.loginAction == 'login' &&
                    <Login
                        auth={this.props.auth}
                        context={this.props.context}
                        model={this.props.auth.loginForm} />
                }
                {this.props.auth.loginAction == 'rest-password-sent' &&
                    <p><strong>{gettext('An email has been sent to your email address.')}</strong></p>
                }
                {this.props.auth.loginAction == 'forgot-password' &&
                    <ForgotPassword
                        auth={this.props.auth}
                        context={this.props.context}
                        resetEmailSent={this.resetEmailSent}
                        model={this.props.auth.forgotPasswordForm} />
                }
            </form>
        </div>;
    }
    get formState() {
        if (this.props.auth.loginAction == 'login') {
            return this.props.auth.loginForm.state;
        } else if (this.props.auth.loginAction == 'register') {
            return this.props.auth.registerForm.state;
        } else if (this.props.auth.loginAction == 'forgot-password') {
            return this.props.auth.forgotPasswordForm.state;
        } else {
            return null;
        }
    }
    doAction(evt) {
        evt.preventDefault();
        if (this.props.auth.loginAction == 'login') {
            this.props.auth.login();
        } else if (this.props.auth.loginAction == 'register') {
            this.props.auth.register();
        } else if (this.props.auth.loginAction == 'forgot-password') {
            this.props.auth.forgotPassword();
        }
    }
}
