
import React from 'react';
import {observer} from 'mobx-react';


@observer export class StoreObserver extends React.Component {
    render() {
        if (this.props.render) {
            return this.props.render(this.props.store);
        }

        var children = React.Children.map(this.props.children, (child)=> {
            return React.cloneElement(child, this.props.store);
        });
        return children[0];
    }
}
