
import React from 'react';
import {observer} from 'mobx-react';
import {observable} from 'mobx';


export var HtmlView = ({content})=> {
    return <div dangerouslySetInnerHTML={{__html: content}}></div>;
};

@observer export class HtmlEditor extends React.Component {
    @observable value;

    constructor(props) {
        super();
        this.text = props.value || '<p></p>';
    }
    componentDidMount() {
        if (this.props.context.clientSide) {
            Promise.all([
                import(/* webpackChunkName: "html-editor" */ 'slate-react'),
                import(/* webpackChunkName: "html-editor" */ './HtmlToolbar')
            ]).then(([slateModule, HtmlToolbarModule])=> {
                this.Toolbar = HtmlToolbarModule.Toolbar;
                this.serializer = HtmlToolbarModule.htmlSerializer;
                this.editorPlugins = HtmlToolbarModule.plugins;
                this.schema = HtmlToolbarModule.schema;
                this.Editor = slateModule.Editor;

                this.value = this.serializer.deserialize(this.text);
            });
        }
    }
    UNSAFE_componentWillReceiveProps(props) {
        if (props.value !== this.text) {
            this.text = props.value;
            if (this.serializer) {
                this.value = this.serializer.deserialize(props.value);
            }
        }
    }
    render() {
        // use this.value is not necessary,
        // but notify to mobx that we are linstening to it.
        if (!this.value || !this.Editor) {
            return null;
        }
        return <div>
            <this.Toolbar
                context={this.props.context}
                value={this.value}
                editor={this.editor}
                universe={this.props.universe}
                characters={this.props.characters}
                pictures={this.props.pictures} />

            <this.Editor
                spellCheck
                autoFocus
                ref={(editor)=> {this.editor = editor;}}
                placeholder={this.props.placeholder}
                className={`form-control clearfix ${this.props.className || ''}`}
                value={this.value}
                onChange={this.onChange}
                schema={this.schema}
                plugins={this.editorPlugins} />
        </div>;
    }

    onChange = (change)=> {
        var text = this.serializer.serialize(change.value);

        if (text !== this.text) {
            this.text = text;
            this.props.onChange(this.text);
        }

        if (this.value != change.value) {
            this.value = change.value;
        }
    }
}
