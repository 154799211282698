import React from 'react';

import {updateMetaData} from './helpers';
import {gettext} from '../i18n';


export function About(props) {
    props.context.title = gettext('About');
    updateMetaData(props.context);

    return <div className="py-3">
        <div className="row">
            <div className="offset-md-3 col-md-6 text-center">
                <h1 className="my-5">{gettext('About')}</h1>
            </div>
        </div>
        <div className="row align-items-stretch text-center">
            <div className="col-sm-4 my-2">
                <div className="card card-body h-100">
                    <strong className="display-3">1</strong>
                    <h2>{gettext('Open games')}</h2>
                    <p>
                        {gettext('Games on Multiversalis are open, you can play with it as much as you want for free.')}
                    </p>
                    <p>
                        {gettext('Any published improvement on this games must be shared with the same rules.')}
                    </p>
                </div>
            </div>
            <div className="col-sm-4 my-2">
                <div className="card card-body h-100">
                    <strong className="display-3">2</strong>
                    <h2>{gettext('Your game')}</h2>
                    <p>
                        {gettext('You keep your ownership control of content you publish on Multiversalis.')}
                    </p>
                    <p>
                        {gettext('Commercial use is NOT allowed without the consent of the author.')}
                    </p>
                </div>
            </div>
            <div className="col-sm-4 my-2">
                <div className="card card-body h-100">
                    <strong className="display-3">3</strong>
                    <h2>{gettext('You rules')}</h2>
                    <p>
                        {gettext('It is your universe rules, your game rules and your publication rules!')}
                    </p>
                    <p>
                        {gettext('Your rules makes authority, feel free to complete or replace these rules with your own.')}
                    </p>
                </div>
            </div>
        </div>
        <div className="my-4 row text-center">
            <div className="col-md-8 offset-md-2">
                <p>
                    {gettext('Multiversalis is provided for free to the community by Jonathan Sanchez')}
                    {' '}
                    (<a href="mailto:jonathan@multiversalis.fun">jonathan@multiversalis.fun</a>).
                    {' '}
                    {gettext('Multiversalis is provided for fictive content, any content that is not related to role playing games or any kind of fiction may be unpublished or removed.')}
                </p>
                <p>
                    {gettext('I take all appropriate measures to protect your personal data against violations, modifications or destruction.')}
                    {' '}
                    {gettext('This measures include: https, Cloudflare DDOS protection, daily db backup, passwords are encrypted, CSP headers, ...')}
                    {' '}
                    <a href="https://observatory.mozilla.org/analyze/multiversalis.fun" target="_blank" rel="noopener noreferrer">Mozilla Observatory</a>
                </p>
                <p>
                    {gettext('Multiversalis contains a non intrusive internal tracker that does not use cookies and respect your "do not track" browser settings.')}
                </p>
            </div>
        </div>
    </div>;
}
