import React from 'react';
import {observer} from 'mobx-react';
import {Link, withRouter} from 'react-router-dom';

import {linkValue, linkChecked} from './helpers';
import {MarkdownView} from './helpers/Markdown';
import {gettext} from '../i18n';
import {UniverseList} from './Universe';

function getDomain(url) {
    try {
        var urlParsed = new URL(url);
        return urlParsed.hostname || '';
    } catch (err) {
        return '';
    }
}

function getDomainName(url) {
    // return the domain name part of an url.
    // f.i.: https://www.[facebook].com/my-profile
    return getDomain(url).split('.').reverse()[1];
}

function getDomainIcon(url) {
    var domainIcons = {
        'facebook': 'facebook',
        'twitter': 'twitter',
        'gratipay': 'gratipay',
        'google': 'google-plus',
        'paypal': 'paypal',
        'twitch': 'twitch',
        'youtube': 'youtube',
        'youtu': 'youtube',
        'medium': 'medium',
        'skype': 'skype',
        'instagram': 'instagram',
        'linkedin': 'linkedin',
        'patreon': 'rebel',
    };

    return domainIcons[getDomainName(url)] || 'globe';
}


@withRouter @observer export class EditUserProfile extends React.Component {
    render() {
        var context = this.props.context;
        var user = this.props.user;
        if (!user) {
            return null;
        }
        return <div>
            <section>
                <form className={`my-4 ${user.state}`} onSubmit={(evt)=> {
                    evt.preventDefault();
                    user.attrs.social_urls = user.attrs.social_urls && user.attrs.social_urls.filter((url)=> url);
                    user.save().then(()=> {
                        this.props.history.push(`/${context.currentLanguage}/profile/me`);
                    });
                }}>
                    <div className="row my-2">
                        <div className="col-4 col-md-3 py-2 text-center">
                            <div>
                                {user && user.attrs.avatar_url &&
                                    <img className="img-fluid" src={user.attrs.avatar_url} />
                                }
                                <label className="btn btn-secondary my-2">
                                    {gettext('Upload my Avatar')}
                                    <input type="file" onChange={this.uploadAvatar.bind(this)} />
                                </label>
                            </div>
                        </div>
                        <div className="col-8 col-md-6">
                            <article className="card card-body">
                                <div className="form-group">
                                    <label>{gettext('Name')}</label>
                                    <input className="form-control h1" type="text" {...linkValue('name', user.attrs)}/>
                                </div>
                                <div className="form-group">
                                    <label>{gettext('Email')}</label>
                                    <input className="form-control" type="email" {...linkValue('email', user.attrs)}/>
                                </div>
                                <div className="form-group">
                                    <label>{gettext('Summary')}</label>
                                    <textarea className="form-control" rows={5} {...linkValue('summary', user.attrs)}>
                                        {user.attrs.summary}
                                    </textarea>
                                </div>
                                <div className="form-group">
                                    <label>
                                        <input className="checkbox" type="checkbox" {...linkChecked('public_email', user.attrs)} />
                                        {' '}
                                        {gettext('Make my email public')}
                                    </label>
                                </div>
                                <div className="form-group">
                                    <h4>{gettext('Social network profiles')}</h4>
                                    {user && (user.attrs.social_urls || []).map((socialUrl, index)=> {
                                        return <div key={index} className="my-2 d-flex flex-row">
                                            <i className={`fa fa-${getDomainIcon(socialUrl)} fa-2x`} style={{width: 40}} />
                                            <input
                                                type="url"
                                                className="form-control"
                                                value={socialUrl}
                                                onChange={(evt)=> {
                                                    if (evt.target.value) {
                                                        user.attrs.social_urls[index] = evt.target.value;
                                                    } else {
                                                        user.attrs.social_urls.splice(index, 1);
                                                    }
                                                }} />
                                        </div>;
                                    }).concat([
                                        <div
                                            className="my-2 d-flex flex-row"
                                            key={user.attrs.social_urls ? user.attrs.social_urls.length : 0}>
                                            <i className="fa fa-plus fa-2x" style={{width: 40}} />
                                            <input
                                                type="url"
                                                className="form-control"
                                                value=""
                                                onChange={(evt)=> {
                                                    if (user.attrs.social_urls) {
                                                        user.attrs.social_urls.push(evt.target.value);
                                                    } else {
                                                        user.attrs.social_urls = [evt.target.value];
                                                    }
                                                }} />
                                        </div>
                                    ])}
                                </div>
                                <button className="btn btn-secondary" type="submit">{gettext('Save')}</button>
                            </article>
                        </div>
                    </div>
                </form>
            </section>
        </div>;
    }
    uploadAvatar(evt) {
        evt.preventDefault();

        var file = evt.target.files[0];
        if (file) {
            this.props.user.uploadAvatar(file);
        }
    }
}

@withRouter @observer export class ChangeUserPassword extends React.Component {
    render() {
        var context = this.props.context;

        return <div>
            <section>
                <form className={`my-4 row ${context.auth.changePasswordForm.state}`} onSubmit={(evt)=> {
                    evt.preventDefault();
                    context.auth.changePasswordForm.save().then(()=> {
                        this.props.history.push(`/${context.currentLanguage}/profile/me`);
                    });
                }}>
                    <div className="offset-md-3 col-md-6">
                        <article className="card card-body">
                            <div className="form-group">
                                <label>{gettext('Old password')}</label>
                                <input
                                    className="form-control"
                                    type="password"
                                    {...linkValue('password', context.auth.changePasswordForm.attrs)} />
                            </div>
                            <div className="form-group">
                                <label>{gettext('New password')}</label>
                                <input
                                    className="form-control"
                                    type="password"
                                    {...linkValue('new_password', context.auth.changePasswordForm.attrs)} />
                            </div>
                            <button className="btn btn-secondary" type="submit">{gettext('Change password')}</button>
                        </article>
                    </div>
                </form>
            </section>
        </div>;
    }
}

@withRouter @observer export class ResetUserPassword extends React.Component {
    render() {
        var context = this.props.context;

        return <div>
            <section>
                <form className={`my-4 row ${context.auth.resetPasswordForm.state}`} onSubmit={(evt)=> {
                    evt.preventDefault();
                    context.auth.resetPasswordForm.attrs.token = this.props.token;
                    context.auth.resetPasswordForm.save().then(()=> {
                        this.props.history.push(`/${context.currentLanguage}/`);
                    });
                }}>
                    <div className="offset-md-3 col-md-6">
                        <article className="card card-body">
                            <div className="form-group">
                                <label>{gettext('Password')}</label>
                                <input
                                    className="form-control"
                                    type="password"
                                    {...linkValue('password', context.auth.resetPasswordForm.attrs)} />
                            </div>
                            <button className="btn btn-secondary" type="submit">{gettext('Reset password')}</button>
                        </article>
                    </div>
                </form>
            </section>
        </div>;
    }
}

@observer export class PublicUserProfile extends React.Component {
    render() {
        var user = this.props.user;
        var context = this.props.context;

        return <div>
            <section>
                <article className="row my-2">
                    <div className="col-4 col-md-3 py-2 text-right">
                        {user && user.attrs.gravatar &&
                            <img className="img-fluid" src={`https://gravatar.com/avatar/${user.attrs.gravatar}?s=200`} />
                        }
                        {user && user.attrs.avatar_url &&
                            <img className="img-fluid" src={user.attrs.avatar_url} />
                        }
                    </div>
                    <div className="col-8 col-md-6">
                        <h1 className="mt-0">{user && user.attrs.name}</h1>
                        {user && user.attrs.summary &&
                            <MarkdownView content={user.attrs.summary} />
                        }
                        {user && user.attrs.email && (user.attrs.public_email === undefined || user.attrs.public_email) &&
                            <p>
                                <a href={`mailto:${user.attrs.email}`}>{user.attrs.email}</a>
                            </p>
                        }
                        {user && (user.attrs.social_urls || []).map((socialUrl, index)=> {
                            return <a
                                key={index}
                                href={socialUrl}
                                title={getDomain(socialUrl)}
                                className="mx-3"
                                target="_blank"
                                rel="noopener noreferrer">
                                <i className={`fa fa-2x fa-${getDomainIcon(socialUrl)}`}></i>
                            </a>;
                        })}
                    </div>
                    {user && (user.id == 'me' || (context.auth.user && user.id == context.auth.user.id)) &&
                        <div className="col-md-3 text-right">
                            <Link to={`/${context.currentLanguage}/profile/me/edit`} className="btn btn-secondary btn-block my-2">
                                {gettext('Edit my profile')}
                            </Link>
                            <Link to={`/${context.currentLanguage}/profile/me/change-password`} className="btn btn-secondary btn-block my-2">
                                {gettext('Change password')}
                            </Link>
                            <Link to={`/${context.currentLanguage}/universe/new`} className="btn btn-primary btn-block my-2">
                                {gettext('Create universe')}
                            </Link>
                        </div>
                    }
                </article>
                {this.props.universes && this.props.universes.models.length > 0 &&
                    <article>
                        <h2>{gettext('Headmaster of Universes')}</h2>
                        <UniverseList universes={this.props.universes} context={context} />
                    </article>
                }
            </section>
        </div>;
    }
}
