
import React from 'react';
import {observer} from 'mobx-react';

import {gettext} from '../i18n';


@observer export class PictureList extends React.Component {
    render() {
        return <div className={`${this.props.pictures.state}`}>
            <div className="text-center mb-2">
                <label className="btn btn-primary">
                    {gettext('Upload a picture')}
                    <input type="file" onChange={this.upload.bind(this)} />
                </label>
            </div>
            {!this.props.pictures.models.length &&
                this.props.pictures.state != 'request' &&
                <div className="text-center">
                    <span className="text-muted">
                        {gettext('There is no pictures here yet...')}
                    </span>
                </div>
            }
            <div className="card-columns">
                {this.props.pictures && this.props.pictures.models.map((picture, index)=> {
                    return <div
                        key={picture.id || 'new-' + index}
                        className={`card my-4 ${picture.state}`}>
                        <button className="close close-delete"
                            onClick={(evt)=> {
                                evt.preventDefault();
                                evt.stopPropagation();
                                picture.delete().then(()=> {
                                    this.props.pictures.remove(picture);
                                });
                            }}><i className="fa fa-times" /></button>
                        {picture.attrs.url ?
                            <img
                                src={picture.attrs.url}
                                onClick={(evt)=> {
                                    evt.preventDefault();
                                    this.props.onPictureSelected(picture);
                                }}
                                className="img-fluid" />
                            :
                            <div className="img-placeholder"></div>
                        }
                    </div>;
                })}
            </div>
        </div>;
    }
    upload(evt) {
        evt.preventDefault();

        Array.prototype.forEach.call(evt.target.files, (file)=> {
            var picture = this.props.pictures.prepend({
                file: file,
                universe_id: this.props.universe.id
            });
            picture.save().then(()=> {
                this.props.onPictureSelected(picture);
            });
        });
    }
}
