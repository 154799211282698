
import React from 'react';
import Transition from 'react-transition-group/Transition';


export class Modal extends React.Component {
    render() {

        if (this.props.open && typeof this.props.children === 'function') {
            this.children = this.props.children(status);
        } else if (this.props.open && this.props.children) {
            this.children = this.props.children;
        }
        return <Transition
            in={!!this.props.open}
            timeout={{
                enter: 0,
                exit: 300,
            }}
            onEnter={()=> {
                document.body.classList.add('modal-open');
            }}
            onExit={()=> {
                document.body.classList.remove('modal-open');
            }}
            mountOnEnter={true}
            unmountOnExit={true}>
            {(state) => {
                var show = ['entered'].includes(state);
                return <div>
                    <div className={`modal fade ${show ? 'show' : ''}`}
                        style={{display: 'block'}}
                        onClick={(evt)=> {
                            if (evt.target == evt.currentTarget) {
                                this.props.onDismiss && this.props.onDismiss();
                            }
                        }}>
                        <div className={`modal-dialog modal-lg ${this.props.className || ''}`}>
                            <div className="modal-content" style={this.props.style}>
                                <div className="modal-body d-flex flex-column">
                                    <div className="clearfix">
                                        <button type="button"
                                            className="close"
                                            onClick={()=> {
                                                this.props.onDismiss && this.props.onDismiss();
                                            }}>
                                            <span>&times;</span>
                                        </button>
                                    </div>
                                    {this.children}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`modal-backdrop fade ${this.props.open ? 'show' : ''}`}></div>
                </div>;
            }}
        </Transition>;
    }
}
