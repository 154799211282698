export default {
    fr: {
        'Welcome': 'Bienvenue',
        'Create your own universe': 'Créez votre univers',
        'Create your account now': 'Créez votre compte',
        'Explore Universes': 'Explorez les univers',
        'Join the discussion on Discord': 'Joignez-vous à la discussion sur Discord',
        'Universes': 'Univers',
        'Universe': 'Univers',
        'Read more about Multiversals': 'En lire plus à propos de Multiversalis',
        'Multiversalis is a collaborative platform to create Role Playing Game Universes.':
            'Multiversalis est une platforme collaborative pour créer des univers de jeux de rôle.',
        'Here you can create, contribute and play with games and scenario from the community.':
            'Ici vous pouvez créer, contribuer et jouer avec les jeux et scénarios de la communauté.',

        // auth
        'Email': 'Email',
        'Password': 'Mot de passe',
        'Name': 'Nom',
        'Profile': 'Profile',
        'Login': 'S\'identifier',
        'Sign in': 'Se connecter',
        'Sign out': 'Se déconnecter',
        'Register': 'S\'enregistrer',
        'New account': 'Nouveau compte',
        'I forgot my password': 'J\'ai oublié mon mot de passe',
        'An email has been sent to your email address.':
            'Un email a été envoyé à votre adresse email.',

        // universe
        'Story': 'Chronique',
        'Stories': 'Chroniques',
        'Character': 'Personnage',
        'Characters': 'Personnages',
        'Create a Story': 'Nouveau texte',
        'Create a Character': 'Nouveau personnage',
        'Create a Section': 'Créer une section',
        'Settings': 'Configurer',
        'Save': 'Enregistrer',
        'Search': 'Chercher',
        'Delete': 'Effacer',
        'Create your first story': 'Créez votre premier texte',
        'There is no stories here yet...': 'Il n\'y a encore aucun texte...',
        'There is no characters here yet...': 'Il n\'y a encore aucun personnage...',
        'There is no pictures here yet...': 'Il n\'y a encore aucune image...',
        'By': 'Par',
        'Universe Headmaster': 'Concepteur de l\'Univers',
        'on': 'le',
        'Search...': 'Rechercher...',
        'Edit': 'Modifier',
        'Add picture': 'Ajouter une image',
        'Add character': 'Ajouter un personnage',
        'Upload a picture': 'Choisir une image',
        'Select a picture': 'Choisir une image',
        'Universe name': 'Nom de l\'univers',
        'Universe presentation...': 'Presentation de l\'univers...',
        'Theme': 'Theme',
        'Parchemin': 'Parchment',
        'Neutral': 'Neutre',
        'Cyber': 'Cyber',
        'Horror': 'Horreur',
        'Bloody': 'Sanglant',
        'Star': 'Soutenir',
        'Unstar': 'Ne plus soutenir',
        'You can not edit this universe! What are you doing here 🤔?':
            'Vous ne pouvez pas modifier cet universe! Que faites-vous ici 🤔?',
        'You can not edit this story! What are you doing here 🤔?':
            'Vous ne pouvez pas modifier ce texte! Que faites-vous ici 🤔?',
        'You can not edit this character! What are you doing here 🤔?':
            'Vous ne pouvez pas modifier ce personnage! Que faites-vous ici 🤔?',

        // story
        'Story title': 'Titre',
        'Story introduction...': 'Introduction...',
        'Write your story...': 'Ecrivez votre texte...',
        'private': 'privé',
        'Author': 'Auteur',
        'Skills': 'Compétences',
        'Inventory': 'Inventaire',
        'Language': 'Langue',
        'English': 'Anglais',
        'French': 'Français',
        'Private (will be visible only by you)': 'Privé (ne sera visible que par vous)',
        'Add table': 'Ajouter une table',
        'Add file': 'Ajouter un fichier',
        'link': 'lien',
        'Move to another universe': 'Déplacer vers un autre univers',
        'You have only one universe...': 'Vous n\'avez qu\'un seul univers',

        // editor
        'rows': 'lignes',
        'columns': 'colonnes',
        'No border': 'Pas de bord',
        'Border': 'Bordure',
        'Thumbnail': 'Vignette',
        'Shadow': 'Ombre',

        // character
        'Duplicate': 'Dupliquer',
        'Create your first character': 'Créer votre premier personnage',
        'Create your Character Sheet': 'Créer votre feuille de personnage',
        'Create Character Sheet': 'Créer la feuille de personnage',
        'Edit Character Sheet': 'Modifier la feuille de personnage',
        'Character sheet': 'Feuille de personnage',
        'This is your basic character sheet, every new character will be a dupliate of this one.':
            'Ceci est votre feuille de personnage de base, tout les nouveaux personnages seront une copie de celle-ci.',
        'This will be your basic character sheet, every new character will be a dupliate of this one.':
            'Ceci sera votre feuille de personnage de base, tout les nouveaux personnages seront une copie de celle-ci.',
        'This is your basic character sheet, you are editing default values for new characters.':
            'Ceci sera votre feuille de personnage de base, vouz editez les valeur par défaut des nouveaux personnages.',
        'Are you sure you want to DELETE this character ?': 'Etes-vous sûr de vouloir EFFACER ce personnage ?',
        'Strength': 'Force',
        'Perception': 'Perception',
        'Endurance': 'Endurance',
        'Will': 'Volonté',
        'Dexterity': 'Dextérité',
        'Intelligence': 'Intelligence',
        'Speed': 'Vitesse',
        'Charisma': 'Charisme',
        'Add': 'Ajouter',
        'Remove': 'Enlever',
        'Add skill': 'Ajouter une compétence',
        'Add item': 'Ajouter un object',
        'Add section': 'Ajouter une section',
        'List of sills will goes here':
            'La liste des compétences viendra ici',
        'List of inventory items will goes here':
            'La liste des objects de l\'inventaire viendra ici',
        'List of items will goes here':
            'La liste de la section viendra ici',
        'Section title': 'Titre de la section',
        'Column name': 'Nom de la colonne',
        'Nothing': 'Aucun',
        'Actions': 'Actions',
        'Initiative': 'Initiative',
        'Natural defence': 'Défence naturelle',
        'Armor': 'Armure',
        'Health': 'Santé',
        'Height': 'Taille',

        // Discussions
        'Discussions': 'Discussions',
        'There is no discussions here yet...': 'Il n\'y a pas encore de discussions...',
        'Open the first discussion': 'Commencer la première discussion',
        'Open discussion': 'Ouvrir une discussion',
        'Discussion subject': 'Sujet de la discussion',
        'Exchange awesome ideas...': 'Echangez d\'impressionnantes idées...',
        'Follow up the discussion': 'Suivre la discussion',
        'Follow discussion': 'Suivre la discussion',
        'Unsubscribe': 'Ne plus suivre',
        'You need to be logged in': 'Vous devez être identifié',
        'Reply': 'Répondre',
        'Response:': 'Réponse:',
        'Write a comment...': 'Ecrivez votre commentaire...',

        // mention
        'Please consider:': 'Veuillez gardez à l\'esprit:',
        'This universe has been created for you, be polite and respectful.': 'Cet univers a été créé pour vous, soyez poli et respectueux.',
        'If you give your opinion, start subject with "opinion:".': 'Si vous donnez votre opinion, commencez le sujet par "opinion:".',
        'This will show that it is your point of view and you are open to discuss.': 'Cela montrera que c\'est votre point de vue et vous êtes ouvert à la discussion.',
        'If you raise an issue, start subject with "issue:".': 'Si vous soulevez un problème, commencez le sujet par "question:".',
        'This will show that you may not understand some content or you found inconsistency that require clarification.': 'Cela montrera que vous avez trouvé des incohérences ou que vous pourriez ne pas comprendre certains contenus qui mérite une clarification.',
        'Remember that only criticisms do not lead to anything, consider giving some advice, provide proposals and alternatives.': 'Rappelez-vous que les critiques seuls ne mènent à rien, pensez à donner des conseils, des propositions et des alternatives.',

        // profile
        'Edit my profile': 'Modifier mon profile',
        'Create universe': 'Créer un univers',
        'Old password': 'Ancien mot de passe',
        'New password': 'Nouveau mot de passe',
        'Change password': 'Changer le mot de passe',
        'Reset password': 'Réinitialiser le mot de passe',
        'Master of Universes': 'Maître des univers',
        'Headmaster of Universes': 'Concepteur des Univers',
        'Summary': 'Résumé',
        'Social network profiles': 'Profiles sur les résaux sociaux',
        'Make my email public': 'Rendre mon email public',
        'Change my Gravatar': 'Changer mon Gravatar',
        'Upload my Avatar': 'Changer mon Avatar',

        // about
        'About': 'A propos',
        'About Multiversalis': 'A propos de Multiversalis',

        'Open games': 'Jeux libres',
        'Games on Multiversalis are open, you can play with it as much as you want for free.':
            'Les jeux sur Multiversalis sont partagés librement, jouez autant que vous voulez gratuitement.',
        'Any published improvement on this games must be shared with the same rules.':
            'Toute amélioration d\'un jeu rendu public doit suivre les même règles.',

        'Your game': 'Vos jeux',
        'You keep your ownership control of content you publish on Multiversalis.':
            'Vous êtes le propriétaire du contenu que vous publiez sur Multiversalis.',
        'If you had written it, it is yours!':
            'Si vous l\'avez écrit, c\'est à vous!',
        'Commercial use is NOT allowed without the consent of the author.':
            'L\'utilisation commerciale n\'est PAS autorisée sans l\'accord de l\'auteur-e.',

        'You rules': 'Vos règles',
        'It is your universe rules, your game rules and your publication rules!':
            'Ce sont vos règles d\'univers, de jeux et de publication!',
        'Your rules makes authority, feel free to complete or replace these rules with your own.':
            'Vos règles font autorité, c\'est vous qui définissez vos règles de publication.',

        'Multiversalis is provided for free to the community by Jonathan Sanchez':
            'Multiversalis est mis à disposition gratuitement pour la communauté par Jonathan Sanchez',
        'Multiversalis is provided for fictive content, any content that is not related to role playing games or any kind of fiction may be unpublished or removed.':
            'Multiversalis est disponible pour du contenu fictif, tout contenu qui n\'est pas en lien avec du jeux ou une forme de fiction pourrait être retiré ou supprimé.',
        'I take all appropriate measures to protect your personal data against violations, modifications or destruction.':
            'Je prends toutes les mesures nécéssaire pour protéger vos données personnelles contre le vol, la modification ou la destruction.',
        'Multiversalis contains a non intrusive internal tracker that does not use cookies and respect your "do not track" browser settings.':
            'Multiversalis contient un traceur non intrusif qui n\'utilise pas les cookies et respecte votre préférence navigateur "ne pas me pister".',
        'This measures include: https, Cloudflare DDOS protection, daily db backup, passwords are encrypted, CSP headers, ...':
            'Ces mesures incluent: https, protection DDOS par Cloudflare, backup db quotidient, les mots de passes sont crypté, headers CSP, ...'
    }
};
