
import React from 'react';
import {observer} from 'mobx-react';
import {observable} from 'mobx';
import {Link, withRouter} from 'react-router-dom';
import {Route, Switch} from 'react-router';
import moment from 'moment';

import {gettext} from '../i18n';
import {StoreObserver} from './helpers/StoreObserver';
import {MarkdownView} from './helpers/Markdown';
import {HtmlView, HtmlEditor} from './helpers/Html';
import {linkValue, linkChecked, linkHtml, updateMetaData} from './helpers';
import {DiscussionModel, DiscussionCollection} from '../models/Discussion';
import {DiscussionCommentModel, DiscussionCommentCollection} from '../models/DiscussionComment';


export class DiscussionStore {
    @observable discussion
    constructor(context, id, universe) {
        this.context = context;
        this.discussion = new DiscussionModel(context);
        this.discussion.initFromContext('discussion');

        this.comments = new DiscussionCommentCollection(context);
        this.comments.initFromContext('comments');
        this.newComment = new DiscussionCommentModel(context, {
            discussion_id: id
        });

        if (this.discussion.id) {
            this.setMetaData(context, this.discussion, universe);
        } else if (id) {
            this.discussion.id = id;
            this.discussion.fetch().then(()=> {
                this.setMetaData(context, this.discussion, universe);
            });
            this.comments.filter({
                discussion_id: id
            });
        }
        if (this.context.clientSide && this.discussion.id && context.auth.loggedIn) {
            this.discussion.checkSubscribed();
        }
    }
    setMetaData(context, discussion, universe) {
        context.title = discussion.attrs.title + ' | ' + universe.attrs.name;
        if (universe.attrs.language && context.currentLanguage != universe.attrs.language) {
            context.changeLanguage(universe.attrs.language);
        }
        updateMetaData(context);
    }
}

export class DiscussionListStore {
    @observable discussions
    constructor(context, universe) {
        this.context = context;
        this.discussions = new DiscussionCollection(context);
        if(!this.discussions.initFromContext('discussions')) {
            this.discussions.filter({
                universe_id: universe.id
            }).then(()=> {
                this.setMetaData(context, universe);
            });
        }
    }
    setMetaData(context, universe) {
        context.title = 'Discussions | ' + universe.attrs.name;
        if (universe.attrs.language && context.currentLanguage != universe.attrs.language) {
            context.changeLanguage(universe.attrs.language);
        }
        updateMetaData(context);
    }
}

export class DiscussionEditStore {
    constructor(context, id, attrs) {
        this.context = context;
        this.discussion = new DiscussionModel(context);

        this.discussion.initFromContext('discussion');
        if (attrs) {
            this.discussion.parse(attrs);
        }
        if (id) {
            this.discussion.id = id;
            this.discussion.fetch();
        }
    }
}

export class DiscussionRouter extends React.Component {
    render() {
        var context = this.props.context;
        var url = this.props.match.url;
        var universe = this.props.universe;
        return <Switch>
            <Route path={`${url}`} exact={true} render={()=> {
                return <StoreObserver store={new DiscussionListStore(context, universe)}>
                    <DiscussionsList universe={universe} />
                </StoreObserver>;
            }} />
            <Route path={`${url}/new`} render={()=> {
                return <StoreObserver store={new DiscussionEditStore(context, null, {
                    universe_id: universe.id
                })}>
                    <DiscussionForm universe={universe} />
                </StoreObserver>;
            }} />
            <Route path={`${url}/:id`} render={({match})=> {
                var discussionId = match.params.id;

                return <Switch>
                    <Route path={`${match.url}/edit`} render={()=> {
                        return <StoreObserver store={new DiscussionEditStore(context, discussionId)}>
                            <DiscussionForm universe={universe} />
                        </StoreObserver>;
                    }} />
                    <Route path={match.url} render={()=> {
                        return <StoreObserver store={new DiscussionStore(context, discussionId, universe)}>
                            <DiscussionView universe={universe} />
                        </StoreObserver>;
                    }} />
                </Switch>;
            }} />
        </Switch>;
    }
}

var CommentView = ({comment, onEdit, onDelete, owned})=> {
    return <div
        key={comment.id}
        className={`my-3 ${comment.state} d-flex ${owned ? 'flex-row-reverse' : 'flex-row'}`}>
        <div>
            <Link to={`/profile/${comment.attrs.user_id}`} className="text-center" style={{display: 'block', maxWidth: 125}}>
                <div>
                    {comment.attrs.user_avatar_url &&
                        <img className="img-fluid" src={comment.attrs.user_avatar_url} />
                    }
                </div>
                <div>
                    {comment.attrs.user_name}
                </div>
            </Link>
        </div>
        <div className={`${owned ? 'mr-3' : 'ml-3'} flex-fill`}>
            <div
                title={moment(comment.attrs.inserted_at).format('L LT')}>
                {moment(comment.attrs.inserted_at).calendar()}
            </div>
            <div className={`card ${owned ? 'owned' : ''} discussion-message py-2 px-3 mb-2`}>
                <MarkdownView content={comment.attrs.content} />
            </div>
            {owned &&
                <div className="text-right">
                    <button className="btn btn-secondary ml-2" onClick={()=> {
                        onEdit();
                    }}><i className="fa fa-pencil" /></button>
                    <button className="btn btn-secondary ml-2" onClick={()=> {
                        onDelete();
                    }}><i className="fa fa-trash" /></button>
                </div>
            }
        </div>
    </div>;
};

@observer
class CommentEdit extends React.Component {
    @observable subscribe = true;

    componentDidMount() {
        this.originalContent = this.props.comment.attrs.content;
    }

    render() {
        var {comment, discussion, onSave, context} = this.props;
        var disabled = !context.auth.loggedIn;

        return <form className="my-4" onSubmit={(evt)=> {
            evt.preventDefault();
            if (!disabled) {
                comment.save().then(()=> {
                    onSave();
                });
            }
        }}>
            <div className="form-group">
                <label className="w-100">
                    {gettext('Response:')}
                    <textarea
                        {...linkValue('content', comment.attrs)}
                        disabled={disabled}
                        className="form-control"
                        rows={8}
                        placeholder={gettext('Write a comment...')} />
                </label>

                {!comment.id &&
                    discussion.subscribed !== null &&
                    !discussion.subscribed &&
                    <div className="form-check">
                        <label className="form-check-label">
                            <input type="checkbox" className="form-check-input" {...linkChecked('subscribe', this)} />
                            {' '}{gettext('Follow up the discussion')}
                        </label>
                    </div>
                }
            </div>

            <button
                disabled={disabled}
                className="btn btn-primary">
                {comment.id ? gettext('Save') : gettext('Reply')}
            </button>
            {comment.id &&
                <button
                    onClick={(e)=> {
                        e.preventDefault();
                        comment.attrs.content = this.originalContent;
                        this.props.onDismiss && this.props.onDismiss();
                    }}
                    disabled={disabled}
                    className="btn btn-secondary ml-2">
                    Cancel
                </button>
            }
            {disabled &&
                <p>
                    <a href="#"
                        onClick={(e)=> {
                            e.preventDefault();
                            document.body.scrollTop = 0;
                            context.auth.loginAction = 'login';
                        }}>
                        ({gettext('You need to be logged in')})
                    </a>
                </p>
            }
        </form>;
    }
}

@withRouter
@observer
export class DiscussionView extends React.Component {
    @observable editCommentId = null;

    render() {
        var context = this.props.context;
        var discussion = this.props.discussion;
        var isDiscutionCreator = context.auth.user && discussion.attrs.user_id == context.auth.user.id;

        return <div className="container py-3">
            <div className="row">
                <div className="col-12 offset-sm-6 col-sm-6 offset-md-8 col-md-4">
                    <div className="d-flex flex-column">
                        <div className="input-group my-1">
                            <div className="input-group-prepend">
                                <strong className="input-group-text">{discussion.attrs.subscriber_count}</strong>
                            </div>
                            <div className="input-group-append flex-fill">
                                <button className="btn btn-secondary btn-block" onClick={()=> {
                                    if (context.auth.loggedIn) {
                                        discussion.toggleSubscribed();
                                    } else {
                                        context.auth.loginAction = 'register';
                                    }
                                }}>
                                    {discussion.subscribed ?
                                        <span><i className="fa fa-envelope"></i>{' '}{gettext('Unsubscribe')}</span>
                                        :
                                        <span>{gettext('Follow discussion')}</span>
                                    }
                                </button>
                            </div>
                        </div>
                        {isDiscutionCreator &&
                            <Link
                                to={`${discussion.getViewUrl(context)}/edit`}
                                className="btn btn-primary flex-fill">{gettext('Edit')}</Link>
                        }
                    </div>
                </div>
            </div>
            <div className={`mb-5 ${discussion.state}`}>
                <div className="d-flex flex-row my-3">
                    <Link to={`/profile/${discussion.attrs.user_id}`} style={{maxWidth: 150}}>
                        <div>
                            {discussion.attrs.user_avatar_url &&
                                <img className="img-fluid" src={discussion.attrs.user_avatar_url} />
                            }
                        </div>
                        <div>
                            {discussion.attrs.user_name}
                        </div>
                    </Link>
                    <div className="ml-3 flex-fill">
                        <div className="card discussion-message py-2 px-3">
                            <h2>
                                {discussion.attrs.title}
                            </h2>
                            <HtmlView content={discussion.attrs.content} />
                        </div>
                    </div>
                </div>
                <div className="text-right">
                    <span title={moment(discussion.attrs.inserted_at).format('L LT')}>
                        {moment(discussion.attrs.inserted_at).calendar()}
                    </span>
                </div>
            </div>
            <div className={discussion.state != 'request' ? this.props.comments.state : ''}>
                {this.props.comments.models.map((comment)=> {
                    if (comment.id == this.editCommentId) {
                        return <CommentEdit
                            comment={comment}
                            key={comment.id}
                            context={context}
                            onDismiss={()=> {
                                this.editCommentId = null;
                            }}
                            onSave={()=> {
                                this.props.comments.filter({
                                    discussion_id: this.props.discussion.id
                                });
                                this.editCommentId = null;
                            }} />;
                    }
                    return <CommentView
                        context={context}
                        comment={comment}
                        key={comment.id}
                        owned={context.auth.user && comment.attrs.user_id == context.auth.user.id}
                        onEdit={()=> {
                            this.editCommentId = comment.id;
                        }}
                        onDelete={()=> {
                            this.props.comments.remove(comment);
                            comment.delete().then(()=> {
                                this.props.comments.filter({
                                    discussion_id: this.props.discussion.id
                                });
                            });
                        }} />;
                })}

                {!this.editCommentId &&
                    <CommentEdit
                        comment={this.props.newComment}
                        discussion={discussion}
                        context={context}
                        onSave={(subscribe)=> {
                            this.props.comments.filter({
                                discussion_id: this.props.discussion.id
                            });
                            this.props.newComment.parse({
                                discussion_id: this.props.discussion.id
                            });
                            if (discussion.subscribed !== null && !discussion.subscribed && subscribe) {
                                discussion.toggleSubscribed(true);
                            }
                        }} />
                }
            </div>
        </div>;
    }
}

@withRouter
@observer
export class DiscussionsList extends React.Component {
    render() {
        var context = this.props.context;
        var disabled = !context.auth.loggedIn;

        return <div className={`container py-4 ${this.props.discussions.state}`}>
            <div className="row">
                {this.props.discussions.models.length != 0 &&
                    this.props.discussions.state != 'request' &&
                    <div className="col-12 offset-sm-6 col-sm-6 offset-md-8 col-md-4 mb-4 text-center">
                        <Link
                            onClick={(e)=> {
                                if (disabled) {
                                    e.preventDefault();
                                }
                            }}
                            className={`btn btn-primary btn-block ${disabled ? 'disabled' : ''}`}
                            to={!disabled ? this.props.discussions.getCreateUrl(context, this.props.universe.id) : ''}>
                            {gettext('Open discussion')}
                        </Link>
                        {disabled &&
                            <p>
                                <a href="#"
                                    onClick={(e)=> {
                                        e.preventDefault();
                                        context.auth.loginAction = 'login';
                                    }}>
                                    ({gettext('You need to be logged in')})
                                </a>
                            </p>
                        }
                    </div>
                }
            </div>
            {!this.props.discussions.models.length &&
                this.props.discussions.state != 'request' &&
                <div className="text-center">
                    <span className="text-muted">{gettext('There is no discussions here yet...')}</span>
                    <div className="my-3">
                        <Link
                            className={`btn btn-primary ${disabled ? 'disabled' : ''}`}
                            to={disabled ? '' : this.props.discussions.getCreateUrl(context, this.props.universe.id)}>
                            {gettext('Open the first discussion')}
                        </Link>
                        <div>
                            {disabled &&
                                <a href="#"
                                    onClick={(e)=> {
                                        e.preventDefault();
                                        context.auth.loginAction = 'login';
                                    }}>
                                    ({gettext('You need to be logged in')})
                                </a>
                            }
                        </div>
                    </div>
                </div>
            }
            <div>
                {this.props.discussions.models.map((discussion)=> {
                    var url = discussion.getViewUrl(context);
                    return <Link to={url}
                        key={discussion.id}
                        className="card card-action discussion card-body my-3">
                        <h2 className="my-3">{discussion.attrs.title}</h2>
                        <HtmlView content={discussion.attrs.content} />
                        <div className="text-right">
                            {discussion.attrs.comments_count} comments
                        </div>
                    </Link>;
                })}
            </div>
        </div>;
    }
}

@withRouter
@observer
export class DiscussionForm extends React.Component {
    @observable subscribe = true;

    render() {
        var context = this.props.context;

        return <div
            className={`container pt-3 story-form ${this.props.discussion.state}`}
            style={{paddingBottom: 70}}>

            <form
                onSubmit={(evt)=> {
                    evt.preventDefault();
                    this.props.discussion.save().then(()=> {
                        var url = this.props.discussion.getViewUrl(context);

                        if (this.subscribe && !this.props.discussion.subscribed) {
                            this.props.discussion.toggleSubscribed(true).then(()=> {
                                this.props.history.push(url);
                            });
                        } else {
                            this.props.history.push(url);
                        }
                    });
                }}>
                {this.props.discussion.errorMessage()}
                <div className="form-group">
                    <input
                        required
                        placeholder={gettext('Discussion subject')}
                        className={`form-control h2 title ${this.props.discussion.errorClass('title')}`}
                        {...linkValue('title', this.props.discussion.attrs)} />
                </div>

                <div className="form-group">
                    <HtmlEditor
                        {...linkHtml('content', this.props.discussion.attrs)}
                        context={context}
                        className={this.props.discussion.errorClass('content')}
                        placeholder={gettext('Exchange awesome ideas...')} />
                </div>

                {!this.props.discussion.id &&
                    <div className="form-check">
                        <label className="form-check-label">
                            <input type="checkbox" className="form-check-input" {...linkChecked('subscribe', this)} />
                            {' '}{gettext('Follow up the discussion')}
                        </label>
                    </div>
                }

                {!this.props.discussion.id &&
                    <div className="my-2">
                        <span>{gettext('Please consider:')}</span>
                        <ul>
                            <li>{gettext('This universe has been created for you, be polite and respectful.')}</li>
                            <li>{gettext('If you give your opinion, start subject with "opinion:".')}
                                {' '}{gettext('This will show that it is your point of view and you are open to discuss.')}</li>
                            <li>{gettext('If you raise an issue, start subject with "issue:".')}
                                {' '}{gettext('This will show that you may not understand some content or you found inconsistency that require clarification.')}</li>
                            <li>{gettext('Remember that only criticisms do not lead to anything, consider giving some advice, provide proposals and alternatives.')}</li>
                        </ul>
                    </div>
                }
                <div className="container fixed-bottom no-pointer-events p-3 d-flex flex-row">
                    <button type="submit" className="btn btn-primary mr-2">{gettext('Save')}</button>
                </div>
            </form>
        </div>;
    }
}
