import React from 'react';
import {observer} from 'mobx-react';
import {Link} from 'react-router-dom';

import {gettext} from '../i18n';
import {DiscordIcon} from '../components/discord.svg';
import {UniverseList} from './Universe';


@observer export class Home extends React.Component {
    render() {
        var context = this.props.context;
        return <div className="home-brand">
            <article className="text-center jumbotron-fluid">
                <div className="lead mx-auto">
                    <h1 className="display-4 brand-title text-primary">Multiversalis</h1>
                    <p>
                        {gettext('Multiversalis is a collaborative platform to create Role Playing Game Universes.')}
                        {' '}
                        {gettext('Here you can create, contribute and play with games and scenario from the community.')}
                    </p>
                    <div className="my-3">
                        {context.auth.loginForm.state != 'request' && context.clientSide &&
                            (context.auth.loggedIn ?
                                <Link className="btn btn-primary"
                                    to={`/${context.currentLanguage}/universe/new`}>
                                    {gettext('Create your own universe')}
                                </Link>
                                :
                                <button className="btn btn-primary" onClick={(evt)=> {
                                    evt.preventDefault();
                                    if (!context.auth.loginAction) {
                                        context.auth.loginAction = 'register';
                                    } else {
                                        context.auth.loginAction = null;
                                    }
                                }}>{gettext('Create your account now')}</button>
                            )
                        }
                    </div>
                    <p>
                        <Link to={`/${context.currentLanguage}/about`}>
                            {gettext('About Multiversalis')}
                        </Link>
                        {' - '}
                        <a href="https://discord.gg/NHS2x8h" target="_blank" rel="noopener noreferrer">
                            {gettext('Join the discussion on Discord')}
                        </a>
                    </p>
                    <div className="social">
                        <a href="https://discord.gg/NHS2x8h" target="_blank" rel="noopener noreferrer">
                            <DiscordIcon className="fa fa-discord" aria-label="Discord" />
                        </a>
                        {' '}
                        <a href="https://fb.me/multiversalis" target="_blank" rel="noopener noreferrer">
                            <i className="fa fa-facebook" aria-label="Facebook" />
                        </a>
                        {' '}
                        <a href="https://twitter.com/multiversalis" target="_blank" rel="noopener noreferrer">
                            <i className="fa fa-twitter" aria-label="Twitter" />
                        </a>
                    </div>
                </div>
            </article>
            <article className="py-4 text-center">
                <h2 className="my-4">{gettext('Explore Universes')}</h2>
                <UniverseList universes={this.props.universes} context={context} />
            </article>
            <footer className="text-center text-muted py-2">
                <div>
                    <small>
                        <a href={`/${context.currentLanguage}/about`}>Multiversalis</a>
                        {' - '}
                        Forged in 🔥 by <a href="mailto:jonathan@multiversalis.fun">Jonathan Sanchez</a>
                    </small>
                </div>
                <div>
                    <small>
                        <a href="https://discord.gg/NHS2x8h" target="_blank" rel="noopener noreferrer">
                            <DiscordIcon className="fa fa-discord" aria-label="Discord" />
                        </a>
                        {' '}
                        <a href="https://fb.me/multiversalis" target="_blank" rel="noopener noreferrer">
                            <i className="fa fa-facebook" aria-label="Facebook" />
                        </a>
                        {' '}
                        <a href="https://twitter.com/multiversalis" target="_blank" rel="noopener noreferrer">
                            <i className="fa fa-twitter" aria-label="Twitter" />
                        </a>
                        {' - '}
                        All Contributions are Welcome
                    </small>
                </div>
            </footer>
        </div>;
    }
}
