/* global ENV, VERSION, DEBUG */

import {setI18nContext} from './i18n';
import {Auth} from './models/Auth';

export var supportedLanguages = ['en', 'fr'];

export class Context {
    constructor() {
        this.auth = new Auth(this);
    }

    get debug() {
        return DEBUG;
    }
    get version() {
        return VERSION;
    }
    get env() {
        return ENV;
    }
    get envName() {
        return this.params && this.params.envName;
    }
    get baseUrl() {
        if (this.serverSide) {
            return this.params && this.params.baseUrl;
        } else {
            return location.origin;
        }
    }
    get backendUrl() {
        if (!this._backendUrl) {
            if (this.serverSide) {
                this._backendUrl = 'http://api:8000';
            } else {
                this._backendUrl = `//${this.backendHost}`;
            }
        }
        return this._backendUrl;
    }
    get backendHost() {
        if (this._backendHost) {
            return this._backendHost;
        }
        if (this.params && this.params.backend) {
            this._backendHost = this.params.backend;
        } else {
            this._backendHost = window.location.host.replace(':8080', ':8000');
        }
        return this._backendHost;
    }
    get clientSide() {
        return ENV == 'browser';
    }
    get serverSide() {
        return !this.clientSide;
    }
    get isSecure() {
        return this.clientSide && window.location.protocol == 'https:';
    }

    set currentLanguage(lang) {
        this._currentLanguage = lang;
        setI18nContext(this);
    }
    get currentLanguage() {
        return this._currentLanguage || 'en';
    }
    save(key, value) {
        try {
            localStorage.setItem(key, value);
        } catch (e) {/* ie bug, just fail silently... */}
    }
    load(key) {
        try {
            return localStorage.getItem(key);
        } catch (e) {/* ie bug, just fail silently... */}
    }
    remove(key) {
        try {
            localStorage.removeItem(key);
        } catch (e) {/* ie bug, just fail silently... */}
    }
    copyToCookie(key) {
        var value = this.load(key);
        if (!value) {
            document.cookie = `${key}=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT`;
            return;
        }
        var date = new Date();
        date.setMonth(date.getMonth() + 1);
        var newCookie = `${key}=${value}; path=/; max-age=${60 * 60 * 24 * 30}; expires=${date.toUTCString()};`;
        if (this.isSecure) {
            newCookie += ' secure;';
        }
        document.cookie = newCookie;
    }

    saveToken(token) {
        if (token) {
            this.save('auth-token', token);
        } else {
            this.remove('auth-token');
        }
        this.copyToCookie('auth-token');
        this.auth.attrs.token = token;
    }
    resetToken() {
        this.saveToken(null);
    }
    restoreToken() {
        this.copyToCookie('auth-token');
        this.auth.attrs.token = this.load('auth-token');
    }
    changeLanguage(lang) {
        var path = this.history.location.pathname.substring(3);
        var url = `/${lang}${path}`;
        if (this.clientSide) {
            document.body.lang = lang;
            this.history.replace(url);
        } else {
            this.status = 301;
            this.redirect = url;
        }
    }
}
