
import translations from './translations';
import moment from 'moment';
import 'moment/locale/fr';

var local = {
    context: null
};

export var setI18nContext = (context)=> {
    moment.locale(context.currentLanguage);
    local.context = context;
};
export var gettext = (key)=> {
    if (local.context.currentLanguage in translations && key in translations[local.context.currentLanguage]) {
        return translations[local.context.currentLanguage][key];
    } else if (local.context.debug && local.context.currentLanguage != 'en') {
        console.warn(`Translation not found for '${key}'`);
    }
    return key;
};
