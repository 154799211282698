
import React from 'react';
import {observer} from 'mobx-react';
import {observable} from 'mobx';
import {Link, withRouter} from 'react-router-dom';
import {Route, Switch} from 'react-router';

import {gettext} from '../i18n';
import {StoreObserver} from './helpers/StoreObserver';
import {linkValue, linkChecked, linkHtml, updateMetaData} from './helpers';
import {MarkdownView} from './helpers/Markdown';
import {HtmlView, HtmlEditor} from './helpers/Html';
import {CharacterSheet} from './Character';
import {UniverseSelectView} from './Universe';
import {PictureList} from './Picture';
import {TableOfContents} from './TableOfContents';
import {Modal} from '../components/Modal';
import {Fade} from '../components/Fade';

import {StoryModel, StoryCollection} from '../models/Story';
import {UniverseCollection} from '../models/Universe';
import {PictureCollection} from '../models/Picture';
import {CharacterCollection, CharacterModel} from '../models/Character';


export class StoryStore {
    @observable story

    constructor(context, id, universe) {
        this.context = context;
        this.story = new StoryModel(context);
        this.story.initFromContext('story');
        if (this.story.id) {
            this.setMetaData(context, this.story, universe);
        } else if (id) {
            this.story.id = id;
            this.story.fetch().then(()=> {
                this.setMetaData(context, this.story, universe);
            });
        }
        this.onDelete = ()=> {
            if (confirm('Are you sure you want to DELETE this story ?')) {
                return this.story.delete();
            } else {
                return Promise.resolve(false);
            }
        };
    }
    setMetaData(context, story, universe) {
        context.title = story.attrs.title + ' | ' + universe.attrs.name;
        context.description = story.attrs.introduction || '';
        context.image = story.attrs.picture_url;
        if (universe.attrs.language && context.currentLanguage != universe.attrs.language) {
            context.changeLanguage(universe.attrs.language);
        }
        updateMetaData(context);
    }
}

class StoryListStore {
    constructor(context, universe) {
        this.context = context;
        this.universe = universe;
        this.stories = new StoryCollection(context);

        if (!this.stories.initFromContext('stories')) {
            this.stories.filter({
                universe_id: universe.id,
                content_type: ['story', 'section']
            });
        }
    }
}

class ExpandedStoryListStore {
    constructor(context, universe) {
        this.context = context;
        this.universe = universe;
        this.stories = new StoryCollection(context);

        if (!this.stories.initFromContext('expandedStories')) {
            this.stories.filter({
                universe_id: universe.id,
                content_type: ['story', 'section']
            }).then(()=> {
                return this.stories.models.map((story)=> {
                    story.fetch();
                });
            });
        }
    }
}

export class StoryEditStore {
    constructor(context, id, attrs) {
        this.context = context;
        this.story = new StoryModel(context);
        this.story.id = id;

        if (attrs) {
            this.story.parse(attrs);
        }
        this.pictures = new PictureCollection(context);
        this.characters = new CharacterCollection(context);

        this.load();
        this.onDelete = ()=> {
            if (confirm('Are you sure you want to DELETE this story ?')) {
                return this.story.delete();
            } else {
                return Promise.resolve(false);
            }
        };
    }
    load() {
        if (!this.story.initFromContext('story')) {
            if (this.story.id) {
                this.story.fetch().then(()=> {
                    this.loadPictures();
                    this.loadCharacters();
                });
            } else {
                this.stories = new StoryCollection(this.context);
                this.stories.filter({
                    universe_id: this.story.attrs.universe_id,
                    content_type: 'story'
                }).then(()=> {
                    this.story.attrs.order = this.stories.models.length;
                });
                this.loadPictures();
                this.loadCharacters();
            }
        }
    }
    loadPictures() {
        return this.pictures.filter({
            universe_id: this.story.attrs.universe_id
        });
    }
    loadCharacters() {
        return this.characters.filter({
            universe_id: this.story.attrs.universe_id
        });
    }
}


export class StoryDuplicateStore {
    constructor(context, id) {
        this.context = context;
        var baseStory = new StoryModel(context, {id: id});
        this.story = new StoryModel(context);

        baseStory.initFromContext('story');

        this.pictures = new PictureCollection(context);
        this.characters = new CharacterCollection(context);

        baseStory.fetch().then(()=> {
            this.loadPictures(baseStory.attrs.universe_id);
            this.loadCharacters(baseStory.attrs.universe_id);
            this.story.parse(baseStory.json());
            delete this.story.attrs.id;

            this.stories = new StoryCollection(context);
            this.stories.filter({
                universe_id: this.story.attrs.universe_id,
                content_type: 'story'
            }).then(()=> {
                this.story.attrs.order = this.stories.models.length;
            });
        });
    }
    loadPictures(universe_id) {
        return this.pictures.filter({
            universe_id: universe_id
        });
    }
    loadCharacters(universe_id) {
        return this.characters.filter({
            universe_id: universe_id
        });
    }
}


export class StoryRouter extends React.Component {
    render() {
        var context = this.props.context;
        var url = this.props.match.url;
        var universe = this.props.universe;
        return <Switch>
            <Route path={url} exact render={()=> {
                return <StoreObserver store={new StoryListStore(context, universe)}>
                    <StoryListView toc={false} />
                </StoreObserver>;
            }} />
            <Route path={`${url}/table-of-contents`} exact render={()=> {
                return <StoreObserver store={new StoryListStore(context, universe)}>
                    <StoryListView toc={true} />
                </StoreObserver>;
            }} />
            <Route path={`${url}/stories/new`} render={()=> {
                return <StoreObserver store={new StoryEditStore(context, null, {
                    universe_id: universe.id,
                    private: false,
                    content_type: 'story'
                })}>
                    <StoryContentForm universe={universe} />
                </StoreObserver>;
            }} />
            <Route path={`${url}/stories/new-section`} render={()=> {
                return <StoreObserver store={new StoryEditStore(context, null, {
                    universe_id: universe.id,
                    private: false,
                    content_type: 'section'
                })}>
                    <StoryContentForm universe={universe} />
                </StoreObserver>;
            }} />
            <Route path={`${url}/stories/all`} render={()=> {
                return <StoreObserver store={new ExpandedStoryListStore(context, universe)}>
                    <StoryExpandedList universe={universe} />
                </StoreObserver>;
            }} />
            <Route path={`${url}/stories/:id`} render={({match})=> {
                var storyId = match.params.id;

                return <Switch>
                    <Route path={`${match.url}/edit`} render={()=> {
                        return <StoreObserver store={new StoryEditStore(context, storyId)}>
                            <StoryContentForm universe={universe} />
                        </StoreObserver>;
                    }} />
                    <Route path={`${match.url}/duplicate`} render={()=> {
                        return <StoreObserver store={new StoryDuplicateStore(context, storyId)}>
                            <StoryContentForm universe={universe} />
                        </StoreObserver>;
                    }} />
                    <Route path={`${match.url}/settings`} render={()=> {
                        return <StoreObserver store={new StoryEditStore(context, storyId)}>
                            <StorySettingsForm universe={universe} />
                        </StoreObserver>;
                    }} />
                    <Route path={match.url} render={()=> {
                        return <StoreObserver store={new StoryStore(context, storyId, universe)}>
                            <StoryView universe={universe} />
                        </StoreObserver>;
                    }} />
                </Switch>;
            }} />
        </Switch>;
    }
}

@withRouter @observer export class StoryView extends React.Component {
    @observable hightlightPicture = null;
    @observable hightlightCharacter = null;
    @observable dropdownStoryOptions = false;
    @observable changeUniverseAction = false;
    @observable showToc = false;

    componentDidUpdate() {
        if (!this.props.story.state && !this.alreadyScrolled && location.hash) {
            var target = document.getElementById(location.hash.substring(1));
            target && target.scrollIntoView({behavior: 'smooth'});
            this.alreadyScrolled = true;
        }
    }
    render() {
        var context = this.props.context;
        var isOwner = this.props.story.isNew() ?
            this.props.universe.isOwner(context.auth.user) :
            this.props.story.isOwner(context.auth.user);
        var storyUrl = this.props.story.getViewUrl(context);
        var listUrl = this.props.story.getListUrl(context);

        return <div className="container py-3">
            <div className="row flex-row-reverse d-print-none sticky">
                <div className="col-12 col-sm-6 col-md-4">
                    {isOwner &&
                        <div className="d-flex flex-column">
                            <div className="btn-group my-1">
                                <Link
                                    to={`${storyUrl}/edit`}
                                    className="btn btn-primary flex-fill">{gettext('Edit')}</Link>
                                <button
                                    className="btn btn-primary dropdown-toggle"
                                    type="button"
                                    onClick={(evt)=> {
                                        evt.preventDefault();
                                        this.dropdownStoryOptions = !this.dropdownStoryOptions;
                                    }}>
                                </button>
                                <div className={'dropdown-menu dropdown-menu-right ' +
                                    (this.dropdownStoryOptions ? 'show' : '')}>
                                    <Link
                                        to={`${storyUrl}/edit`}
                                        className="dropdown-item">
                                        {gettext('Edit')}
                                    </Link>
                                    <Link
                                        to={`${storyUrl}/settings`}
                                        className="dropdown-item">
                                        {gettext('Settings')}
                                    </Link>
                                    <Link
                                        to={`${storyUrl}/duplicate`}
                                        className="dropdown-item">
                                        {gettext('Duplicate')}
                                    </Link>
                                    <button
                                        onClick={(evt)=> {
                                            evt.preventDefault();
                                            this.universes = new UniverseCollection(context);
                                            this.universes.filter({owner_id: context.auth.user.id});
                                            this.changeUniverseAction = true;
                                            this.dropdownStoryOptions = false;
                                        }}
                                        className="dropdown-item">
                                        {gettext('Move to another universe')}
                                    </button>
                                    <button className="dropdown-item"
                                        onClick={(evt)=> {
                                            evt.preventDefault();
                                            this.props.onDelete().then((deleted)=> {
                                                if (deleted) {
                                                    this.props.history.push(
                                                        this.props.universe.getViewUrl(context)
                                                    );
                                                } else {
                                                    this.dropdownStoryOptions = false;
                                                }
                                            });
                                        }}>{gettext('Delete')}</button>
                                </div>
                            </div>
                        </div>
                    }
                </div>
                <div className="col">
                    {this.props.story.attrs.table_of_contents && this.props.story.attrs.table_of_contents.length > 0 &&
                        <button
                            onClick={()=> {
                                this.showToc = !this.showToc;
                            }}
                            className={`my-1 d-print-none btn btn-secondary fade ${this.showToc ? '' : 'show'}`}>
                            <i className="fa fa-bars" />
                        </button>
                    }
                </div>
            </div>
            <div id="top" className={`content story ${this.props.story.state}`}
                onClick={(evt)=> {
                    if (evt.target.tagName.toLowerCase() == 'img') {
                        evt.preventDefault();
                        this.hightlightPicture = evt.target;
                    }
                    var node = evt.target;
                    while (node && node.classList && !node.classList.contains('character')) {
                        node = node.parentNode;
                    }
                    if (node && node.classList && node.classList.contains('character') && node.id) {
                        evt.preventDefault();

                        var character = new CharacterModel(context, {id: node.id});
                        character.fetch().then(()=> {
                            this.hightlightCharacter = character;
                        });
                    }
                }}>
                <h1>
                    {this.props.story.attrs.title}
                </h1>
                <Fade
                    open={this.showToc && this.props.story.attrs.table_of_contents && this.props.story.attrs.table_of_contents.length > 0}
                    className="floating-card card card-body table-of-contents">

                    <button
                        onClick={(evt)=> {
                            evt.preventDefault();
                            this.showToc = false;
                        }}
                        className="close">&times;</button>

                    <TableOfContents
                        title={this.props.story.attrs.title}
                        toc={this.props.story.attrs.table_of_contents} />
                </Fade>
                <HtmlView content={this.props.story.attrs.content} />
                <div className="clearfix"></div>
            </div>
            <div className="row">
                <div className="my-1 col-sm-6 col-md-4">
                    {this.props.story.attrs.previous_story_id &&
                        <Link
                            to={`${listUrl}/stories/${this.props.story.attrs.previous_story_id}`}
                            className="btn btn-secondary btn-block">
                            <i className="fa fa-chevron-left pull-left my-1" />
                            {this.props.story.attrs.previous_story_title}
                        </Link>
                    }
                </div>
                <div className="my-1 col-sm-6 col-md-4 offset-md-4">
                    {this.props.story.attrs.next_story_id &&
                        <Link
                            to={`${listUrl}/stories/${this.props.story.attrs.next_story_id}`}
                            className="btn btn-secondary btn-block">
                            <i className="fa fa-chevron-right pull-right my-1" />
                            {this.props.story.attrs.next_story_title}
                        </Link>
                    }
                </div>
            </div>

            <Modal open={this.hightlightPicture} onDismiss={()=> {
                this.hightlightPicture = null;
            }} className="modal-full-width">
                {()=>
                    <div className="text-center">
                        <img className="img-fluid" src={this.hightlightPicture.src} />
                    </div>
                }
            </Modal>

            <Modal open={this.hightlightCharacter} onDismiss={()=> {
                this.hightlightCharacter = null;
            }} className="modal-full-width">
                {()=>
                    <CharacterSheet story={this.hightlightCharacter} />
                }
            </Modal>

            <Modal open={this.changeUniverseAction} onDismiss={()=> {
                this.changeUniverseAction = false;
                this.universes = null;
            }}>
                {this.changeUniverseAction &&
                    <UniverseSelectView
                        universes={this.universes.models.filter((u)=>
                            u.id !== this.props.story.attrs.universe_id
                        )}
                        onSelect={(universe)=> {
                            this.props.story.attrs.universe_id = universe.id;
                            this.props.story.save().then(()=> {
                                this.universes = null;
                                var url = this.props.story.getViewUrl(context);
                                this.props.history.push(url);
                            });
                            this.changeUniverseAction = false;
                        }} />
                }
            </Modal>
        </div>;
    }
}

@withRouter @observer export class StoryContentForm extends React.Component {

    render() {
        var context = this.props.context;
        var disabled = !this.props.story.state && !this.props.universe.state && (this.props.story.isNew() ?
            !this.props.universe.isOwner(context.auth.user) :
            !this.props.story.isOwner(context.auth.user)
        );

        return <div
            className={`container pt-3 story-form ${this.props.story.state}`}
            style={{paddingBottom: 70}}>

            {disabled &&
                <p className="text-danger">{gettext('You can not edit this story! What are you doing here 🤔?')}</p>
            }

            <form
                onSubmit={(evt)=> {
                    evt.preventDefault();
                    this.props.story.save().then(()=> {
                        var url;
                        if (this.props.story.attrs.content_type == 'story') {
                            url = this.props.story.getViewUrl(context);
                        } else {
                            url = this.props.story.getListUrl(context);
                        }
                        this.props.history.push(url);
                    });
                }}>
                {this.props.story.errorMessage() && <p className="text-danger">{this.props.story.errorMessage()}</p>}
                <div>
                    <div className="form-group">
                        <input
                            disabled={disabled}
                            required
                            placeholder={gettext('Story title')}
                            className={`form-control h1 title ${this.props.story.errorClass('title')}`}
                            {...linkValue('title', this.props.story.attrs)} />
                    </div>
                    <HtmlEditor {...linkHtml('content', this.props.story.attrs)}
                        className="content story"
                        context={context}
                        disabled={disabled}
                        placeholder={gettext('Write your story...')}
                        pictures={this.props.pictures}
                        characters={this.props.characters}
                        universe={this.props.universe} />

                    <div className="clearfix"></div>
                </div>

                <div className="container fixed-bottom no-pointer-events p-3 d-flex flex-row">
                    <button type="submit" className="btn btn-primary mr-2" disabled={disabled}>
                        {gettext('Save')}
                    </button>
                </div>
            </form>
        </div>;
    }
}

@withRouter @observer export class StorySettingsForm extends React.Component {
    @observable selectPicture = false;

    render() {
        var context = this.props.context;
        var disabled = !this.props.story.state && !this.props.universe.state && (this.props.story.isNew() ?
            !this.props.universe.isOwner(context.auth.user) :
            !this.props.story.isOwner(context.auth.user)
        );

        return <form
            className={`story-form pt-3 ${this.props.story.state}`}
            style={{paddingBottom: 70}}
            onSubmit={(evt)=> {
                evt.preventDefault();
                this.props.story.save().then(()=> {
                    var url;

                    if (this.props.story.attrs.content_type == 'story') {
                        url = this.props.story.getViewUrl(context);
                    } else {
                        url = this.props.story.getListUrl(context);
                    }
                    this.props.history.push(url);
                });
            }}>
            {this.props.story.errorMessage() && <p className="text-danger">{this.props.story.errorMessage()}</p>}
            {disabled &&
                <p className="text-danger">{gettext('You can not edit this story! What are you doing here 🤔?')}</p>
            }

            <div className="row">
                <div className="col-12">
                    <div className="form-group">
                        <input
                            disabled={disabled}
                            placeholder={gettext('Story title')}
                            required
                            className={`form-control h2 ${this.props.story.errorClass('title')}`}
                            {...linkValue('title', this.props.story.attrs)} />
                    </div>
                </div>
                {this.props.story.attrs.content_type == 'story' &&
                    <div className="col-8">
                        <div className="form-group">
                            <textarea
                                disabled={disabled}
                                placeholder={gettext('Story introduction...')}
                                className={`form-control ${this.props.story.errorClass('introduction')}`}
                                rows={6}
                                {...linkValue('introduction', this.props.story.attrs)} />
                        </div>
                    </div>
                }
                {this.props.story.attrs.content_type == 'story' &&
                    <div className="col-4">
                        <div className={`form-group ${this.props.story.errorClass('picture_id')}`}>
                            {this.props.story.attrs.picture_url &&
                                <div className="text-center mb-3">
                                    <img src={this.props.story.attrs.picture_url}
                                        className="img-fluid img-thumbnail" />
                                </div>
                            }
                            <button className="btn btn-block btn-secondary"
                                disabled={disabled}
                                onClick={(evt)=> {
                                    evt.preventDefault();
                                    this.selectPicture = true;
                                }}>{gettext('Select a picture')}</button>
                        </div>
                    </div>
                }
            </div>

            <div className="form-group">
                <label>
                    <input
                        disabled={disabled}
                        type="checkbox"
                        {...linkChecked('private', this.props.story.attrs)} />
                    {' '}
                    {gettext('Private (will be visible only by you)')}
                </label>
            </div>

            <div className="fixed-bottom no-pointer-events p-3">
                <button
                    className="btn btn-danger pull-right"
                    disabled={disabled}
                    onClick={(evt)=> {
                        evt.preventDefault();
                        this.props.onDelete().then((deleted)=> {
                            if (deleted) {
                                this.props.history.push(
                                    this.props.universe.getViewUrl(context)
                                );
                            } else {
                                this.dropdownStoryOptions = false;
                            }
                        });
                    }}>{gettext('Delete')}</button>
                <button type="submit" disabled={disabled} className="btn btn-primary">Save</button>
            </div>

            <Modal open={this.selectPicture} onDismiss={()=> {
                this.selectPicture = false;
            }}>
                <PictureList
                    pictures={this.props.pictures}
                    universe={this.props.universe}
                    onPictureSelected={(picture)=> {
                        this.props.story.attrs.picture_id = picture.id;
                        this.props.story.attrs.picture_url = picture.attrs.url;
                        this.selectPicture = false;
                    }} />
            </Modal>
        </form>;
    }
}

@withRouter @observer export class StoryList extends React.Component {

    moveUp(story, evt) {
        evt.preventDefault();
        evt.stopPropagation();
        var stories = this.props.stories.models.slice().sort(
            (a, b)=> a.attrs.order - b.attrs.order
        );

        if (story.attrs.order == 0) {return;}

        stories.splice(stories.indexOf(story), 1);
        stories.splice(story.attrs.order - 1, 0, story);

        stories.forEach((s, index)=> {
            if (s.attrs.order != index) {
                s.attrs.order = index;
                s.save(['order']);
            }
        });
    }
    moveDown(story, evt) {
        evt.preventDefault();
        evt.stopPropagation();
        var stories = this.props.stories.models.slice().sort(
            (a, b)=> a.attrs.order - b.attrs.order
        );

        stories.splice(stories.indexOf(story), 1);
        stories.splice(story.attrs.order + 1, 0, story);

        stories.forEach((s, index)=> {
            if (s.attrs.order != index) {
                s.attrs.order = index;
                s.save(['order']);
            }
        });
    }
    render() {
        var context = this.props.context;
        var isOwner = context.auth.user && this.props.universe.isOwner(context.auth.user);

        return <div>
            {!this.props.stories.models.length &&
                this.props.stories.state != 'request' &&
                <div className="text-center">
                    <span className="text-muted">{gettext('There is no stories here yet...')}</span>
                    {context.auth.user && this.props.universe.attrs.owner_id == context.auth.user.id &&
                        <div className="my-3">
                            <Link
                                className="btn btn-primary"
                                to={`${this.props.universe.getViewUrl(context)}/stories/new`}>
                                {gettext('Create your first story')}
                            </Link>
                        </div>
                    }
                </div>
            }
            {this.props.stories.models.slice().sort(
                (a, b)=> a.attrs.order - b.attrs.order
            ).map((story)=> {
                if(story.attrs.content_type == 'section') {
                    return this.renderSectionNav(story, isOwner);
                } else {
                    return this.renderStoryNav(story, isOwner);
                }
            })}
        </div>;
    }
    renderStoryNav(story, isOwner) {
        var context = this.props.context;
        var url = story.getViewUrl(context);
        return <Link to={url}
            key={story.id}
            className="card card-action card-body mb-2">
            <section>
                {story.attrs.picture_url &&
                    <img src={story.getResizedPictureUrl('500x700')}
                        className="img-fluid ml-3 mb-2 pull-right"
                        style={{ maxHeight: 200 }} />
                }
                {story.attrs.private &&
                    <em className="pull-right">({gettext('private')})</em>
                }
                <div>
                    {isOwner &&
                        <div className="pull-left mr-3">
                            <div onClick={this.moveUp.bind(this, story)}>
                                <i className="fa fa-caret-up" />
                            </div>
                            <div onClick={this.moveDown.bind(this, story)}>
                                <i className="fa fa-caret-down" />
                            </div>
                            {context.debug &&
                                <div>{story.attrs.order}</div>
                            }
                        </div>
                    }
                    <h2 className="my-3">{story.attrs.title}</h2>
                    <MarkdownView content={story.attrs.introduction} />
                </div>
            </section>
        </Link>;
    }
    renderSectionNav(story, isOwner) {
        var context = this.props.context;
        return <section key={story.id} className="clearfix">
            {isOwner &&
                <div className="pull-left mr-3">
                    <div onClick={this.moveUp.bind(this, story)}>
                        <i className="fa fa-caret-up" />
                    </div>
                    <div onClick={this.moveDown.bind(this, story)}>
                        <i className="fa fa-caret-down" />
                    </div>
                    {context.debug &&
                        <div>{story.attrs.order}</div>
                    }
                </div>
            }
            {isOwner &&
                <div className="pull-right">
                    <Link to={story.getEditUrl(context)}
                        className="btn btn-secondary ml-3">
                        {gettext('Edit')}
                    </Link>
                    <Link to={story.getSettingsUrl(context)}
                        className="btn btn-secondary ml-3">
                        {gettext('Settings')}
                    </Link>
                </div>
            }
            {story.attrs.private &&
                <em className="pull-right">({gettext('private')})</em>
            }
            <h2 className="my-3">{story.attrs.title}</h2>
            <HtmlView content={story.attrs.content} />
        </section>;
    }
}

var StoryListTocIndex = ({toc, url})=> {
    return toc ? <ol>
        {toc.map((item, index)=> {
            return <li key={index}>
                {item.label &&
                    <Link to={`${url}#${item.slug}`}>
                        {item.label}
                    </Link>
                }
                {item.children && item.children.length > 0 &&
                    <StoryListTocIndex
                        url={url}
                        toc={item.children} />
                }
            </li>;
        })}
    </ol> : null;
};

@observer class StoryListTableOfContent extends React.Component {
    render() {
        var context = this.props.context;
        var stories = this.props.stories.models.reduce((list, story)=> {
            if (story.attrs.content_type == 'section') {
                story.children = [];
                list.push(story);
                return list;
            }
            if (list[list.length - 1] && list[list.length - 1].attrs.content_type == 'section') {
                list[list.length - 1].children.push(story);
            } else {
                list.push(story);
            }
            return list;
        }, []);
        return <ol>
            {stories.map((story)=> {
                var url = story.getViewUrl(context);
                return <li key={story.id}>
                    <h2>
                        <Link to={url}>
                            {story.attrs.title}
                        </Link>
                    </h2>
                    <StoryListTocIndex
                        url={url}
                        toc={story.attrs.table_of_contents} />
                    {story.children &&
                        <ol>
                            {story.children.map((story)=> {
                                var url = story.getViewUrl(context);
                                return <li key={story.id}>
                                    <h3>
                                        <Link to={url}>
                                            {story.attrs.title}
                                        </Link>
                                    </h3>
                                    <StoryListTocIndex
                                        url={url}
                                        toc={story.attrs.table_of_contents} />
                                </li>;
                            })}
                        </ol>
                    }
                </li>;
            })}
        </ol>;
    }
}

@observer export class StoryListView extends React.Component {
    @observable search = '';
    @observable dropdownCreateStoryOpen = false;
    @observable showToc = false;

    render() {
        var context = this.props.context;
        var universe = this.props.universe;

        return <article className={`${this.props.stories.state} py-4 container`}>

            <div className="sticky row flex-row-reverse">
                <form className="col-12 col-md-8 mb-4"
                    onSubmit={(evt)=> {
                        evt.preventDefault();
                        this.props.stories.filter({
                            universe_id: this.props.universe.id,
                            search: this.search
                        });
                    }}>
                    <label className="input-group mb-0">
                        <input
                            className="form-control"
                            type="search"
                            placeholder={gettext('Search...')}
                            {...linkValue('search', this)} />
                        <div className="input-group-append">
                            <button className="btn btn-secondary btn-block">
                                <i className="fa fa-search" aria-label={gettext('Search')} />
                            </button>
                        </div>
                    </label>
                </form>
                {this.props.stories.models.length > 0 &&
                    <div className="col mb-4">
                        <Link
                            to={this.props.toc ? this.props.universe.getViewUrl(context) : this.props.universe.getTableOfContentsUrl(context)}
                            className={`d-print-none btn btn-secondary ${this.showToc ? 'active' : ''}`}>
                            <i className="fa fa-bars" />
                        </Link>
                    </div>
                }
            </div>
            <div className="row flex-row-reverse">
                {context.auth.user &&
                    universe.attrs.owner_id == context.auth.user.id &&
                    this.props.stories.models.length > 0 &&
                    <div className="col-12 offset-sm-6 col-sm-6 col-md-4 mb-4">
                        <div className="btn-group btn-block">
                            <Link className="btn btn-primary flex-fill"
                                to={`${universe.getViewUrl(context)}/stories/new`}>
                                {gettext('Create a Story')}
                            </Link>
                            <button
                                className="btn btn-primary dropdown-toggle"
                                type="button"
                                onClick={(evt)=> {
                                    evt.preventDefault();
                                    this.dropdownCreateStoryOpen = !this.dropdownCreateStoryOpen;
                                }}>
                            </button>
                            <div className={'dropdown-menu dropdown-menu-right ' +
                                (this.dropdownCreateStoryOpen ? 'show' : '')}>
                                <Link
                                    to={`${universe.getViewUrl(context)}/stories/new`}
                                    className="dropdown-item">{gettext('Create a Story')}</Link>
                                <Link
                                    to={`${universe.getViewUrl(context)}/stories/new-section`}
                                    className="dropdown-item">{gettext('Create a Section')}</Link>
                            </div>
                        </div>
                    </div>
                }
            </div>
            {this.props.toc ?
                <StoryListTableOfContent
                    stories={this.props.stories}
                    universe={this.props.universe}
                    context={context} />
                :
                <StoryList
                    stories={this.props.stories}
                    universe={this.props.universe}
                    context={context} />
            }
        </article>;
    }
}

@observer export class StoryExpandedList extends React.Component {

    render() {
        return <article className={`${this.props.stories.state} py-4 container`}>
            <section className="d-flex flex-column justify-content-center h-100">
                <h1 className="text-center">{this.props.universe.attrs.name}</h1>
                <img src={this.props.universe.attrs.picture_url} className="img-fluid" />
                <p className="text-center my-3">
                    {gettext('Author')}: <strong>{this.props.universe.attrs.owner_name}</strong>
                </p>
            </section>
            {this.props.stories.models.map((story)=> {
                return <section key={story.id} className="content story">
                    <h2>{story.attrs.title}</h2>
                    <HtmlView content={story.attrs.content} />
                </section>;
            })}
        </article>;
    }
}
