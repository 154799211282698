
import Model from './Model';

export class UserModel extends Model {
    static schema = {
        email: {type: String},
        name: {type: String},
        summary: {type: String},
        public_email: {type: Boolean},
        social_urls: {type: Array},
    };
    get url() {
        if (this.id) {
            return `/api/users/${this.id}`;
        }
        return '/api/users';
    }
    uploadAvatar(file) {
        this.state = 'request';
        return this.api.post(`${this.url}/avatar`, {
            file: file
        }, 'multipart/form-data').then((json)=> {
            return this.parse(json);
        }).catch((err)=> {
            return this.handleError(err);
        });
    }
}
