
import '../css/main.scss';
import 'whatwg-fetch';
import 'babel-polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter, HashRouter} from 'react-router-dom';
import Raven from 'raven-js';
import {onReactionError} from 'mobx';

import {Context} from './Context';
import {MainPage} from './App';

onReactionError((err)=> {
    console.error(err);
});

var localContext = new Context();
var contextEl = document.getElementById('context');

if (contextEl) {
    localContext.params = JSON.parse(contextEl.innerText);
} else {
    localContext.params = {};
}

if (localContext.params && localContext.params.sentryDNS) {
    Raven.config(localContext.params.sentryDNS, {
        env_name: context.env,
        release: context.version
    }).install();
}

window.addEventListener('unhandledrejection', (err)=> {
    console.warn('unhandledrejection', err);
    Raven.captureException(err.reason);
});

if (!localContext.params.error) {
    localContext.restoreToken();
    localContext.auth.status();

    if (localContext.params.backend) {
        // init from server side rendering
        ReactDOM.hydrate(<BrowserRouter>
            <MainPage context={localContext} />
        </BrowserRouter>, document.getElementById('main'));
    } else {
        ReactDOM.render(<HashRouter>
            <MainPage context={localContext} />
        </HashRouter>, document.getElementById('main'));
    }
}

