
import Model from './Model';
import Collection from './Collection';

export class StoryModel extends Model {
    static schema = {
        title: {type: String},
        content: {type: String},
        content_type: {type: String},
        introduction: {type: String},
        universe_id: {type: Number},
        private: {type: Boolean}
    };

    get url() {
        if (this.id) {
            return `/api/stories/${this.id}`;
        }
        return '/api/stories';
    }
    getViewUrl(context) {
        return `/${context.currentLanguage}/universe/${this.attrs.universe_id}/stories/${this.id}`;
    }
    getEditUrl(context) {
        return `/${context.currentLanguage}/universe/${this.attrs.universe_id}/stories/${this.id}/edit`;
    }
    getSettingsUrl(context) {
        return `/${context.currentLanguage}/universe/${this.attrs.universe_id}/stories/${this.id}/settings`;
    }
    getListUrl(context) {
        return `/${context.currentLanguage}/universe/${this.attrs.universe_id}`;
    }
    getResizedPictureUrl(size) {
        return this.attrs.picture_path && `//${this.context.backendHost}/api/resize/${size}?path=/${this.attrs.picture_path}`;
    }
    isOwner(user) {
        return user && this.attrs.owner_id === user.id;
    }
}

export class StoryCollection extends Collection {
    static modelClass = StoryModel;
    url = '/api/stories';
}
