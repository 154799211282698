import React from 'react';
import {Route, Switch} from 'react-router';
import {Link, withRouter} from 'react-router-dom';
import {observable} from 'mobx';
import {observer} from 'mobx-react';
import Raven from 'raven-js';

import {gettext} from './i18n';
import {UniverseCollection} from './models/Universe';
import {UserModel} from './models/User';
import {RestApiBackend} from './models/Backend';

import {updateMetaData} from './views/helpers';
import {StoreObserver} from './views/helpers/StoreObserver';
import {Home} from './views/Home';
import {Showcase} from './views/Showcase';
import {UniverseRouter} from './views/Universe';
import {Random} from './views/Random';
import {About} from './views/About';
import {EditUserProfile, PublicUserProfile, ChangeUserPassword, ResetUserPassword} from './views/UserProfile';
import {AuthForm} from './views/AuthForm';
import {Fade, Backdrop} from './components/Fade';

export var NotFound = ({staticContext}) => {
    if (staticContext) {
        staticContext.status = 404;
    }
    return <div className="text-sm-center py-4">
        <h1>Whoops</h1>
        <a href="/">
            <img className="img-thumbnail" src="/img/404.jpg" />
        </a>
        <p>Sorry but no page could be found here.</p>
    </div>;
};

export var ServerError = ({staticContext}) => {
    if (staticContext) {
        staticContext.status = 500;
    }
    return <div className="text-sm-center py-4">
        <h1>Ouch!</h1>
        <a href="/">
            <img className="img-thumbnail" src="/img/404.jpg" />
        </a>
        <p>Sorry but there is some stuff not working nicely yet...</p>
    </div>;
};

@withRouter @observer class Header extends React.Component {
    @observable loginOpen = false;

    render() {
        var context = this.props.context;
        var auth = this.props.context.auth;

        return <nav className="navbar navbar-dark bg-dark">
            <Link to={`/${context.currentLanguage}/`} className="brand navbar-brand btn btn-outline-light">
                {/*<img src="/img/logo-white.svg"
                    alt="Multiversalis" />*/}
                <i className="fa fa-home" aria-label={gettext('Welcome')} />
            </Link>
            {context.envName !== 'production' &&
                <h2 className="text-warning my-0">{context.envName}</h2>
            }
            <div className="navbar-nav ml-auto d-flex flex-row" id="navigation">
                {auth.loggedIn &&
                    <span className="nav-item">
                        <Link to={`/${context.currentLanguage}/profile/me`} className="btn btn-outline-light mr-2">
                            <i className="fa fa-user-circle" aria-label={gettext('Profile')} />
                        </Link>
                    </span>
                }
                {auth.loggedIn &&
                    <span className="nav-item">
                        <button className="btn btn-outline-info" onClick={()=> {
                            auth.loginAction = null;
                            auth.logout().then(()=> {
                                this.props.history.push(`/${context.currentLanguage}/`);
                            });
                        }}><i className="fa fa-sign-out" aria-label={gettext('Sign out')} /></button>
                    </span>
                }
                {!auth.loggedIn &&
                    <span className="nav-item">
                        <button className="btn btn-outline-warning" onClick={()=> {
                            auth.loginAction = auth.loginAction ? null : 'login';
                        }}>{gettext('Login')}</button>
                    </span>
                }
            </div>
            <Fade open={!auth.loggedIn && auth.loginAction}>
                <AuthForm auth={auth}  context={context} />
                <Backdrop
                    open={!auth.loggedIn && auth.loginAction}
                    onClickOutside={()=> {
                        auth.loginAction = null;
                    }} />
            </Fade>
            {this.props.children}
        </nav>;
    }
}

class HomeStore {
    @observable universes;

    constructor(context) {
        this.context = context;
        this.universes = new UniverseCollection(context);
        if (!this.universes.initFromContext('universes')) {
            this.universes.fetch();
        }
        context.title = gettext('Welcome');
        context.description = gettext('Multiversalis is a collaborative platform to create Role Playing Game Universes.');
        context.image = context.baseUrl + '/img/background-brand-4.png';
        updateMetaData(context);
    }
}
class PublicUserProfileStore {
    @observable universes;
    @observable user;

    constructor(context, userId) {
        this.context = context;
        this.universes = new UniverseCollection(context);
        if (!this.universes.initFromContext('universes') && this.context.clientSide) {
            this.universes.filter({owner_id: userId});
        }
        this.user = new UserModel(context, {id: userId});
        if (!this.user.initFromContext('user') && this.context.clientSide) {
            this.user.fetch().then(()=> {
                this.setMetaData(context, this.user);
            });
        } else {
            this.setMetaData(context, this.user);
        }
    }
    setMetaData(context, user) {
        context.title = user.attrs.name;
        context.description = user.attrs.summary;
        context.image = `https://gravatar.com/avatar/${user.attrs.gravatar}?s=200`;
        updateMetaData(context);
    }
}
class CurrentUserProfileStore {
    @observable universes;
    @observable user;

    constructor(context) {
        this.context = context;

        if (!context.auth.loggedIn) {
            this.user = null;
            this.universes = null;
            return;
        }
        this.user = new UserModel(context, {id: 'me'});
        this.universes = new UniverseCollection(context);
        if (!this.user.initFromContext('user') && this.context.clientSide) {
            this.user.fetch().then(()=> {
                this.setMetaData(context, this.user);
                this.universes.filter({owner_id: this.user.id});
            });
        } else {
            this.setMetaData(context, this.user);
            if (!this.universes.initFromContext('universes') && this.context.clientSide) {
                this.universes.filter({owner_id: this.user.id});
            }
        }
    }
    setMetaData(context, user) {
        context.title = user.attrs.name;
        updateMetaData(context);
    }
}
class EditUserProfileStore {
    @observable user;

    constructor(context) {
        this.context = context;
        this.user = new UserModel(context, {id: 'me'});
        if (this.context.clientSide) {
            this.user.fetch();
        }
    }
}

@withRouter @observer export class MainPage extends React.Component {
    @observable error;

    constructor(props) {
        super();
        var history = props.history;
        props.context.history = history;
        if (history && props.context.clientSide) {
            console.log(
                props.context.debug ? 'debug' : 'release',
                props.context.env,
                props.context.version,
                props.context.backendUrl
            );
            this.api = new RestApiBackend(props.context);
            this.unsubscribeFromHistory = history.listen(this.handleLocationChange.bind(this));
            this.handleLocationChange(history.location);
        }
    }
    handleLocationChange(location) {
        if (this.pathname != location.pathname && navigator.doNotTrack !== '1' && navigator.doNotTrack !== 1) {
            this.api.post('/api/log', {path: location.pathname});
        }
        this.pathname = location.pathname;
    }
    componentDidCatch(error, errorInfo) {
        console.error(error);
        Raven.captureException(error, {extra: errorInfo});
        this.error = error;
    }
    render() {
        var context = this.props.context;

        if (this.error) {
            return <ServerError />;
        }

        return <Switch>
            <Route path="/:language([a-z]{2})" render={({match: {params, url}})=> {
                context.currentLanguage = params.language;

                return <main className="container-fluid">
                    <Header context={context} />
                    <Switch>
                        <Route exact path={`${url}/`} render={()=> {
                            return <StoreObserver store={new HomeStore(context)}>
                                <Home />
                            </StoreObserver>;
                        }} />

                        <Route path={`${url}/universe`} render={({match})=> {
                            return <UniverseRouter context={context} match={match} />;
                        }} />

                        <Route path={`${url}/profile/me/edit`} render={()=> {
                            return <StoreObserver store={new EditUserProfileStore(context)}>
                                <EditUserProfile />
                            </StoreObserver>;
                        }} />
                        <Route path={`${url}/profile/me/change-password`} render={()=> {
                            return <StoreObserver store={new EditUserProfileStore(context)}>
                                <ChangeUserPassword />
                            </StoreObserver>;
                        }} />
                        <Route path={`${url}/reset-password/:token`} render={({match: {params}})=> {
                            return <ResetUserPassword context={context} token={params.token} />;
                        }} />
                        <Route path={`${url}/profile/me`} render={()=> {
                            return <StoreObserver store={new CurrentUserProfileStore(context)}>
                                <PublicUserProfile />
                            </StoreObserver>;
                        }} />
                        <Route path={`${url}/profile/:id`} render={({match: {params}})=> {
                            return <StoreObserver store={new PublicUserProfileStore(context, params.id)}>
                                <PublicUserProfile />
                            </StoreObserver>;
                        }} />

                        <Route path={`${url}/random`} component={Random} />
                        <Route path={`${url}/showcase`} render={()=> {
                            return <Showcase context={context} />;
                        }} />
                        <Route path={`${url}/about`} render={()=> {
                            return <About context={context} />;
                        }} />
                        <Route component={NotFound} />
                    </Switch>
                </main>;
            }} />
            <Route render={()=> {
                var lang = context.currentLanguage || 'en';
                var redirectUrl = `/${lang}${this.props.history.location.pathname}`;
                if (context.clientSide) {
                    this.props.history.replace(redirectUrl);
                    return null;
                } else {
                    context.status = 301;
                    context.redirect = redirectUrl;
                    return null;
                }
            }} />
        </Switch>;
    }
}
