import React from 'react';
import {HtmlEditor} from './helpers/Html';

class SampleCard extends React.Component {
    render() {
        return <div className={`my-2 card container-fluid universe-theme ${this.props.theme}`}>
            <div className="card-body">
                <div className="content story">
                    <h1>Theme {this.props.theme}</h1>
                    <h2>Title h2</h2>
                    <h3>Title h3</h3>
                    <h4>Title h4</h4>
                    <p className="card float-right">
                        <strong>left</strong>{' '}
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                    </p>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                        quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                        consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
                        cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                        proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                    </p>
                </div>
            </div>
        </div>;
    }
}

export class SampleEditor extends React.Component {
    render() {
        return <div className={`my-2 card universe-theme ${this.props.theme}`}>
            <div className="card-body">
                <div className="content story">
                    <div className="row mb-2">
                        <div className="col-3">
                            <input className="form-control" type="text" placeholder="Text..." />
                        </div>
                        <div className="col-3">
                            <button className="btn btn-block btn-secondary" type="text">button</button>
                        </div>
                        <div className="col-3">
                            <button className="btn btn-block btn-primary" type="text">button</button>
                        </div>
                        <div className="col-3">
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <strong className="input-group-text">1</strong>
                                </div>
                                <div className="input-group-append flex-fill">
                                    <button className="btn btn-secondary btn-block">button</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <HtmlEditor
                        context={this.props.context}
                        value={'<p></p><table><tr><td>table</td><td>table</td></tr><tr><td>table</td><td>table</td></tr></table><img src="/img/404.jpg" />'}
                        onChange={()=> {}} />
                </div>
            </div>
        </div>;
    }
}

export class SampleComponent extends React.Component {
    render() {
        return <div className={`my-2 card ${this.props.theme}`}>
            <div className="card-body">
                <div className="content story">
                    <p>
                        <img src="/img/404.jpg" width={200} className="img-thumbnail" />
                        {' '}
                        <img src="/img/404.jpg" width={200} className="img-border" />
                        {' '}
                        <img src="/img/404.jpg" width={200} className="img-shadow" />
                    </p>
                </div>
            </div>
        </div>;
    }
}

export class Showcase extends React.Component {
    render() {
        return <div className="py-3">
            <div className="my-2 card">
                <div className="card-body">
                    {' '}<button className="btn btn-primary">Button</button>
                    {' '}<button className="btn btn-secondary">Button</button>
                    {' '}<button className="btn btn-info">Button</button>
                    {' '}<button className="btn btn-warning">Button</button>
                    {' '}<button className="btn btn-danger">Button</button>
                    {' '}<button className="btn btn-dark">Button</button>
                    {' '}<button className="btn btn-light">Button</button>
                </div>
            </div>
            <div className="my-2 card bg-dark">
                <div className="card-body">
                    {' '}<button className="btn btn-outline-primary">Button</button>
                    {' '}<button className="btn btn-outline-secondary">Button</button>
                    {' '}<button className="btn btn-outline-info">Button</button>
                    {' '}<button className="btn btn-outline-warning">Button</button>
                    {' '}<button className="btn btn-outline-danger">Button</button>
                    {' '}<button className="btn btn-outline-dark">Button</button>
                    {' '}<button className="btn btn-outline-light">Button</button>
                </div>
            </div>

            <SampleCard theme="clasic" />
            <SampleCard theme="scifi" />
            <SampleCard theme="medieval" />
            <SampleCard theme="horror" />
            <SampleCard theme="bloody" />

            <SampleEditor theme="clasic" context={this.props.context} />
            <SampleEditor theme="scifi" context={this.props.context} />
            <SampleEditor theme="medieval" context={this.props.context} />
            <SampleEditor theme="horror" context={this.props.context} />
            <SampleEditor theme="bloody" context={this.props.context} />

            <SampleComponent theme="clasic" />
            <SampleComponent theme="scifi" />
            <SampleComponent theme="medieval" />

            <div className="my-2 card">
                <div className="card-body" style={{fontSize: 26}}>
                    <p style={{fontFamily: 'Exo'}}>Exo éçèàêÚÒÂæî º®†‡ 😁&#x1f3b2;</p>
                    <p style={{fontFamily: 'Orbitron'}}>Orbitron éçèàêÚÒÂæî º®†‡ 😁&#x1f3b2;</p>
                    <p style={{fontFamily: 'AquilineTwo'}}>AquilineTwo éçèàêÚÒÂæî º®†‡ 😁&#x1f3b2;</p>
                    <p style={{fontFamily: 'Leander'}}>Leander éçèàêÚÒÂæî º®†‡ 😁&#x1f3b2;</p>
                    <p style={{fontFamily: 'De Valencia'}}>De Valencia éçèàêÚÒÂæî º®†‡ 😁&#x1f3b2;</p>
                    <p style={{fontFamily: 'Avara'}}>Avara éçèàêÚÒÂæî º®†‡ 😁&#x1f3b2;</p>
                    <p style={{fontFamily: 'Futura'}}>Futura éçèàêÚÒÂæî º®†‡ 😁&#x1f3b2;</p>
                </div>
            </div>
        </div>;
    }
}
