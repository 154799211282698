import React from 'react';
import {observable, autorun} from 'mobx';
import {observer} from 'mobx-react';
import {Link, withRouter} from 'react-router-dom';
import {Route, Switch} from 'react-router';

import {gettext} from '../i18n';
import {linkValue, linkChecked, updateMetaData} from './helpers';
import {StoreObserver} from './helpers/StoreObserver';
import {MarkdownView} from './helpers/Markdown';
import {PictureList} from './Picture';
import {UniverseSelectView} from './Universe';
import {Modal} from '../components/Modal';

import {CharacterModel, CharacterCollection} from '../models/Character';
import {UniverseModel, UniverseCollection} from '../models/Universe';
import {PictureCollection} from '../models/Picture';


export class CharacterStore {
    @observable story
    constructor(context, id, universe) {
        this.context = context;
        this.story = new CharacterModel(context);
        this.universe = universe;

        if (id){
            this.story.id = id;
        }

        if (this.story.initFromContext('story')) {
            this.setMetaData(context, this.story, universe);
        } else {
            if (this.story.id) {
                this.story.fetch().then(()=> {
                    this.setMetaData(context, this.story, universe);
                });
            }
        }
    }
    setMetaData(context, story, universe) {
        context.title = story.attrs.title + ' | ' + universe.attrs.name;
        context.description = story.attrs.introduction || '';
        context.image = story.attrs.picture_url;
        updateMetaData(context);
    }
}

export class CharacterSheetStore {
    constructor(context, universe, attrs) {
        this.context = context;
        this.story = new CharacterModel(context);
        this.universe = universe;

        if (!this.story.initFromContext('story')) {
            this.universe.fetch().then(()=> {
                if (this.universe.attrs.character_sheet_id) {
                    this.story.id = this.universe.attrs.character_sheet_id;
                    this.story.fetch();
                } else {
                    if (attrs) {
                        this.story.parse(attrs);
                        this.story.setInitialLayout();
                    }

                    this.stories = new CharacterCollection(context);
                    this.stories.filter({
                        universe_id: this.story.attrs.universe_id
                    }).then(()=> {
                        this.story.attrs.order = this.stories.models.length;
                    });
                }
            });
        }
    }
    loadPictures() {
        return this.pictures.filter({
            universe_id: this.story.attrs.universe_id
        });
    }
}

export class CharacterEditStore {
    constructor(context, id, attrs) {
        this.context = context;
        this.story = new CharacterModel(context);

        if (id) {
            this.story.id = id;
        }

        this.story.state = 'request';
        if (!this.story.initFromContext('story')) {
            this.pictures = new PictureCollection(context);

            if (this.story.id) {
                this.story.fetch().then(()=> {
                    this.loadPictures();
                    this.universe = new UniverseModel(context);
                    this.universe.id = this.story.attrs.universe_id;
                    this.universe.fetch();
                });
            } else {
                this.story.parse(attrs);
                this.story.state = 'request';

                this.loadPictures();

                // duplicate character sheet if available
                this.universe = new UniverseModel(context);
                this.universe.id = this.story.attrs.universe_id;
                this.universe.fetch().then(()=> {
                    if (this.universe.attrs.character_sheet_id) {
                        var characterSheet = new CharacterModel(context);
                        characterSheet.id = this.universe.attrs.character_sheet_id;
                        characterSheet.fetch().then(()=> {
                            this.story.parse(characterSheet.json());
                            delete this.story.attrs.id;
                            this.story.attrs.title = '';

                            this.stories = new CharacterCollection(context);
                            this.stories.filter({
                                universe_id: this.story.attrs.universe_id
                            }).then(()=> {
                                // decrement order because character sheet is not part of order
                                this.story.attrs.order = this.stories.models.length - 1;
                            });
                        });
                    } else {
                        this.story.state = '';
                        this.story.setInitialLayout();
                    }
                });
            }
        }
    }
    loadPictures() {
        return this.pictures.filter({
            universe_id: this.story.attrs.universe_id
        });
    }
}

export class CharacterDuplicateStore {
    constructor(context, id) {
        this.context = context;
        var baseStory = new CharacterModel(context, {id: id});
        this.story = new CharacterModel(context);

        baseStory.initFromContext('story');
        this.pictures = new PictureCollection(context);

        baseStory.fetch().then(()=> {
            this.loadPictures(baseStory.attrs.universe_id);
            this.story.parse(baseStory.json());
            delete this.story.attrs.id;

            this.stories = new CharacterCollection(context);
            this.stories.filter({
                universe_id: this.story.attrs.universe_id
            }).then(()=> {
                this.story.attrs.order = this.stories.models.length;
            });
        });
    }
    loadPictures(universe_id) {
        return this.pictures.filter({
            universe_id: universe_id
        });
    }
}
class CharacterListStore {
    constructor(context, universe) {
        this.context = context;
        this.universe = universe;
        this.stories = new CharacterCollection(context);

        if (!this.stories.initFromContext('characters')) {
            this.stories.filter({
                universe_id: universe.id
            });
        }
    }
}

export class CharacterRouter extends React.Component {
    render() {
        var context = this.props.context;
        var url = this.props.match.url;
        var universe = this.props.universe;

        return <Switch>
            <Route path={url} exact render={()=> {
                return <StoreObserver store={new CharacterListStore(context, universe)}>
                    <CharacterListView />
                </StoreObserver>;
            }} />
            <Route path={`${url}/new`} render={()=> {
                return <StoreObserver store={new CharacterEditStore(context, null, {
                    universe_id: universe.id,
                    private: false,
                    content_type: 'character',
                })}>
                    <CharacterEditForm universe={universe} />
                </StoreObserver>;
            }} />
            <Route path={`${url}/sheet`} render={()=> {
                return <StoreObserver store={new CharacterSheetStore(context, universe, {
                    title: gettext('Character sheet'),
                    universe_id: universe.id,
                    private: false,
                    content_type: 'character',
                })}>
                    <CharacterSettingsForm universe={universe} sheet={true} />
                </StoreObserver>;
            }} />
            <Route path={`${url}/:id`} render={({match})=> {
                var storyId = match.params.id;

                return <Switch>
                    <Route path={`${match.url}/edit`} render={()=> {
                        return <StoreObserver store={new CharacterEditStore(context, storyId)}>
                            <CharacterEditForm universe={universe} />
                        </StoreObserver>;
                    }} />
                    <Route path={`${match.url}/duplicate`} render={()=> {
                        return <StoreObserver store={new CharacterDuplicateStore(context, storyId)}>
                            <CharacterEditForm universe={universe} />
                        </StoreObserver>;
                    }} />
                    <Route path={`${match.url}/settings`} render={()=> {
                        return <StoreObserver store={new CharacterEditStore(context, storyId)}>
                            <CharacterSettingsForm universe={universe} />
                        </StoreObserver>;
                    }} />
                    <Route path={match.url} render={()=> {
                        return <StoreObserver store={new CharacterStore(context, storyId, universe)}>
                            <CharacterView universe={universe} />
                        </StoreObserver>;
                    }} />
                </Switch>;
            }} />
        </Switch>;
    }
}


@withRouter @observer export class CharacterView extends React.Component {
    @observable dropdownStoryOptions = false;
    @observable changeUniverseAction = false;

    render() {
        var context = this.props.context;
        var isOwner = context.auth.user && this.props.story.isOwner(context.auth.user);
        var characterUrl = this.props.story.getViewUrl(context);

        return <div className="container-fluid py-3">
            {isOwner &&
                <div className="row my-1 d-print-none">
                    <div className="col-12 offset-sm-6 col-sm-6 offset-md-8 col-md-4 d-flex flex-column">
                        <div className="btn-group my-1">
                            <Link
                                to={`${characterUrl}/edit`}
                                className="btn btn-primary flex-fill">{gettext('Edit')}</Link>
                            <button
                                className="btn btn-primary dropdown-toggle"
                                type="button"
                                onClick={(evt)=> {
                                    evt.preventDefault();
                                    this.dropdownStoryOptions = !this.dropdownStoryOptions;
                                }}>
                            </button>
                            <div className={`dropdown-menu dropdown-menu-right ${this.dropdownStoryOptions ? 'show' : ''}`}>
                                <Link
                                    to={`${characterUrl}/edit`}
                                    className="dropdown-item">{gettext('Edit')}</Link>
                                <Link
                                    to={`${characterUrl}/settings`}
                                    className="dropdown-item">{gettext('Settings')}</Link>
                                <Link
                                    to={`${characterUrl}/duplicate`}
                                    className="dropdown-item">{gettext('Duplicate')}</Link>
                                <button className="dropdown-item" onClick={(evt)=> {
                                    evt.preventDefault();
                                    if (confirm(gettext('Are you sure you want to DELETE this character ?'))) {
                                        this.props.story.delete().then(()=> {
                                            this.props.history.push(this.props.universe.getCharactersListUrl(context));
                                            this.props.universe.fetch();
                                        });
                                    }
                                }}>{gettext('Delete')}</button>
                                <button
                                    onClick={(evt)=> {
                                        evt.preventDefault();
                                        this.universes = new UniverseCollection(context);
                                        this.universes.filter({owner_id: context.auth.user.id});
                                        this.changeUniverseAction = true;
                                        this.dropdownStoryOptions = false;
                                    }}
                                    className="dropdown-item">
                                    {gettext('Move to another universe')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            }
            <div className={`content ${this.props.story.state}`}>
                <CharacterSheet story={this.props.story} />
            </div>
            <Modal open={this.changeUniverseAction} onDismiss={()=> {
                this.changeUniverseAction = false;
                this.universes = null;
            }}>
                {this.changeUniverseAction &&
                    <UniverseSelectView
                        universes={this.universes.models.filter((u)=>
                            u.id !== this.props.story.attrs.universe_id
                        )}
                        onSelect={(universe)=> {
                            this.props.story.attrs.universe_id = universe.id;
                            this.props.story.save().then(()=> {
                                this.universes = null;
                                var url = this.props.story.getViewUrl(context);
                                this.props.history.push(url);
                            });
                            this.changeUniverseAction = false;
                        }} />
                }
            </Modal>
        </div>;
    }
}

@withRouter @observer class CharacterEditForm extends React.Component {
    save() {
        this.props.story.save().then(()=> {
            var context = this.props.context;
            var url = this.props.story.getViewUrl(context);
            this.props.history.push(url);
        });
    }
    render() {
        var context = this.props.context;
        var disabled = !this.props.story.state && !this.props.universe.state && (this.props.story.isNew() ?
            !this.props.universe.isOwner(context.auth.user) :
            !this.props.story.isOwner(context.auth.user)
        );

        return <div className={`container-fuild pt-3 story-form ${this.props.story.state}`}
            style={{paddingBottom: 70}}>
            {this.props.story.errorMessage() && <p className="text-danger">{this.props.story.errorMessage()}</p>}

            {disabled &&
                <p className="text-danger">{gettext('You can not edit this character! What are you doing here 🤔?')}</p>
            }
            {this.props.story.attrs.content &&
                <CharacterEditor story={this.props.story}
                    pictures={this.props.pictures}
                    universe={this.props.universe}
                    disabled={disabled}
                    onSubmit={()=> {
                        this.save();
                    }}
                    onDelete={()=> {
                        if (confirm(gettext('Are you sure you want to DELETE this character ?'))) {
                            this.props.story.delete().then(()=> {
                                this.props.history.push(this.props.universe.getCharactersListUrl(this.props.context));
                                this.props.universe.fetch();
                            });
                        }
                    }} />
            }
        </div>;
    }
}

@withRouter @observer class CharacterSettingsForm extends React.Component {
    @observable attributes
    @observable states
    @observable sections
    @observable layout

    constructor(props) {
        super();
        this.parseStory(props.story);
    }
    UNSAFE_componentWillReceiveProps(props) {
        this.parseStory(props.story);
    }
    parseStory(story) {
        autorun(()=> {
            this.content = JSON.parse(story.attrs.content);
            if (this.content) {
                this.attributes = observable(Object.keys(this.content.attributes));
                this.attributesValues = observable(Object.values(this.content.attributes));
                this.states = observable(Object.keys(this.content.state));
                this.statesValues = observable(Object.values(this.content.state));
                this.sections = observable(this.content.sections || []);
                this.layout = observable.object(this.content.layout || {
                    skillEnabled: true,
                    skillLabel: '',
                    inventoryEnabled: true,
                    inventoryLabel: ''
                });
            }
        });
    }

    getAttributes() {
        var attributes = {};
        this.attributes.forEach((key, index)=> {
            attributes[key] = this.attributesValues[index];
        });
        return attributes;
    }
    getState() {
        var state = {};
        this.states.forEach((key, index)=> {
            state[key] = this.statesValues[index];
        });
        return state;
    }
    save() {
        var context = this.props.context;

        this.props.story.attrs.content = JSON.stringify(this.content, null, 2);
        this.props.story.save().then(()=> {
            if (this.props.sheet) {
                this.props.universe.attrs.character_sheet_id = this.props.story.id;
                this.props.universe.save().then(()=> {
                    this.props.history.push(this.props.story.getViewUrl(context));
                });
            } else {
                this.props.history.push(this.props.story.getViewUrl(context));
            }
        });
    }

    render() {
        var context = this.props.context;
        var disabled = !this.props.story.state && !this.props.universe.state && (this.props.story.isNew() ?
            !this.props.universe.isOwner(context.auth.user) :
            !this.props.story.isOwner(context.auth.user)
        );

        return <form
            className={`pt-3 content ${this.props.story.state}`}
            style={{paddingBottom: 70}}
            onSubmit={(evt)=> {
                evt.preventDefault();
                this.content.attributes = this.getAttributes();
                this.content.state = this.getState();
                this.content.sections = this.sections;
                this.content.layout = this.layout;
                this.save();
            }}>
            {this.props.story.errorMessage() && <p className="text-danger">{this.props.story.errorMessage()}</p>}

            {disabled &&
                <p className="text-danger">{gettext('You can not edit this character! What are you doing here 🤔?')}</p>
            }
            <div className="form-group">
                <label>
                    <input
                        type="checkbox"
                        disabled={disabled}
                        {...linkChecked('private', this.props.story.attrs)} />
                    {' '}
                    {gettext('Private (will be visible only by you)')}
                </label>
            </div>

            {this.props.sheet && this.props.story.isNew() &&
                <div className="form-group">
                    {gettext('This will be your basic character sheet, every new character will be a dupliate of this one.')}
                </div>
            }
            {!this.props.story.isNew() && this.props.universe.attrs.character_sheet_id == this.props.story.id &&
                <div className="form-group">
                    {gettext('This is your basic character sheet, every new character will be a dupliate of this one.')}
                </div>
            }

            <div className="character">
                <div className="d-flex">
                    <div className="presentation">
                        <div className="d-flex d-flex flex-column flex-sm-row">
                            <div className="character-picture mr-3 my-2 text-center">
                                <div className="img-placeholder border border-dark">
                                </div>
                            </div>
                            <div className="my-2 flex-fill">
                                <h2>{this.props.story.attrs.title}</h2>
                                <blockquote className="blockquote my-2 flex-fill">
                                    {this.props.story.attrs.introduction &&
                                        <MarkdownView content={this.props.story.attrs.introduction} />
                                    }
                                </blockquote>
                            </div>
                        </div>
                        <div className="section attribute-container">
                            <div className="w-100 d-flex align-items-center align-content-between flex-wrap">
                                {this.attributes && this.attributes.map((attribute, index)=> {
                                    return <div className="attribute input-group" key={index}>
                                        <input
                                            disabled={disabled}
                                            className="form-control"
                                            onChange={(evt)=> {
                                                this.attributes.splice(index, 1, evt.target.value);
                                            }}
                                            value={attribute} />
                                        <div className="input-group-append">
                                            <button type="button"
                                                disabled={disabled}
                                                className="btn btn-secondary"
                                                onClick={(evt)=> {
                                                    evt.preventDefault();
                                                    this.attributes.splice(index, 1);
                                                    this.attributesValues.splice(index, 1);
                                                }}>
                                                <i className="fa fa-trash" />
                                            </button>
                                        </div>
                                    </div>;
                                })}
                                <div className="attribute input-group">
                                    <button type="button"
                                        disabled={disabled}
                                        className="btn btn-secondary btn-block"
                                        onClick={(evt)=> {
                                            evt.preventDefault();
                                            this.attributes.push('');
                                            var lastValues = this.attributesValues[this.attributesValues.length - 1];
                                            this.attributesValues.push(lastValues || 0);
                                        }}>{gettext('Add')}</button>
                                </div>
                            </div>
                        </div>
                        <div className="section attribute-container state">
                            <div className="w-100 section d-flex align-items-center align-content-between flex-wrap">
                                {this.states && this.states.map((state, index)=> {
                                    return <div className="attribute input-group" key={index}>
                                        <input
                                            disabled={disabled}
                                            className="form-control"
                                            onChange={(evt)=> {
                                                this.states.splice(index, 1, evt.target.value);
                                            }}
                                            value={state} />
                                        <div className="input-group-append">
                                            <button
                                                disabled={disabled}
                                                className="btn btn-secondary"
                                                onClick={(evt)=> {
                                                    evt.preventDefault();
                                                    this.states.splice(index, 1);
                                                    this.statesValues.splice(index, 1);
                                                }}>
                                                <i className="fa fa-trash" />
                                            </button>
                                        </div>
                                    </div>;
                                })}
                                <div className="attribute input-group">
                                    <button type="button"
                                        disabled={disabled}
                                        className="btn btn-secondary btn-block"
                                        onClick={(evt)=> {
                                            evt.preventDefault();
                                            this.states.push('');
                                            var lastValues = this.statesValues[this.statesValues.length - 1];
                                            this.statesValues.push(lastValues || 0);
                                        }}>{gettext('Add')}</button>
                                </div>
                            </div>
                        </div>

                        {this.sections && this.sections.map((section, index)=> {
                            return <div className="section" key={index}>
                                <div className="input-group">
                                    <input
                                        disabled={disabled}
                                        className="form-control h3"
                                        onChange={(evt)=> {
                                            section.title = evt.target.value;
                                        }}
                                        placeholder={gettext('Section title')}
                                        value={section.title} />
                                    <div className="input-group-append">
                                        <button
                                            disabled={disabled}
                                            type="button"
                                            className="btn btn-secondary"
                                            onClick={(evt)=> {
                                                evt.preventDefault();
                                                this.sections.splice(index, 1);
                                            }}>
                                            <i className="fa fa-trash" aria-label={gettext('Remove')}></i>
                                        </button>
                                    </div>
                                </div>
                                <table className="table">
                                    <thead><tr>
                                        {section.attributes.map((name, index)=> {
                                            return <td key={index}>
                                                <div className="input-group">
                                                    <input
                                                        disabled={disabled}
                                                        className="form-control"
                                                        onChange={(evt)=> {
                                                            section.attributes.splice(index, 1, evt.target.value);
                                                        }}
                                                        placeholder={gettext('Column name')}
                                                        value={name} />
                                                    <div className="input-group-append">
                                                        <button
                                                            disabled={disabled}
                                                            type="button"
                                                            className="btn btn-secondary"
                                                            onClick={(evt)=> {
                                                                evt.preventDefault();
                                                                section.attributes.splice(index, 1);
                                                            }}>
                                                            <i className="fa fa-trash" aria-label={gettext('Remove')}></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </td>;
                                        })}
                                        <td style={{width: 61}}>
                                            <button
                                                disabled={disabled}
                                                type="button"
                                                className="btn btn-secondary"
                                                onClick={(evt)=> {
                                                    evt.preventDefault();
                                                    section.attributes.push('');
                                                }}>
                                                <i className="fa fa-plus" aria-label={gettext('Add')}></i>
                                            </button>
                                        </td>
                                    </tr></thead>

                                    <tbody><tr>
                                        <td colSpan={section.attributes.length} className="text-muted">
                                            {gettext('List of items will goes here')}
                                        </td>
                                        <td><br/></td>
                                    </tr></tbody>
                                </table>
                            </div>;
                        })}
                        <div className="section">
                            <button type="button"
                                disabled={disabled}
                                className="btn btn-secondary"
                                onClick={(evt)=> {
                                    evt.preventDefault();
                                    this.sections.push({
                                        title: '',
                                        items: [],
                                        attributes: ['']
                                    });
                                }}>{gettext('Add section')}</button>
                        </div>
                    </div>
                    <div className={`skills ${!this.layout || this.layout.skillEnabled ? '' : 'disabled'}`}>
                        {this.layout &&
                            <div className="form-group">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <label className="input-group-text">
                                            <input type="checkbox"
                                                disabled={disabled}
                                                {...linkChecked('skillEnabled', this.layout)} />
                                        </label>
                                    </div>

                                    <input className="h2 form-control"
                                        disabled={disabled}
                                        {...linkValue('skillLabel', this.layout)}
                                        placeholder={gettext('Skills')} />
                                </div>
                            </div>
                        }
                        <p className="text-muted">{gettext('List of sills will goes here')}</p>
                    </div>
                </div>
                <div className={`inventory ${!this.layout || this.layout.inventoryEnabled ? '' : 'disabled'}`}>
                    {this.layout &&
                        <div className="form-group">
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <label className="input-group-text">
                                        <input type="checkbox"
                                            disabled={disabled}
                                            {...linkChecked('inventoryEnabled', this.layout)} />
                                    </label>
                                </div>
                                <input className="h2 form-control"
                                    disabled={disabled}
                                    {...linkValue('inventoryLabel', this.layout)}
                                    placeholder={gettext('Inventory')} />
                            </div>
                        </div>
                    }
                    <p className="text-muted">{gettext('List of inventory items will goes here')}</p>
                </div>
            </div>

            <div className="fixed-bottom no-pointer-events p-3">
                {!this.props.story.isNew() &&
                    <button className="btn btn-danger pull-right" disabled={disabled} onClick={(evt)=> {
                        evt.preventDefault();
                        if (confirm(gettext('Are you sure you want to DELETE this character ?'))) {
                            this.props.story.delete().then(()=> {
                                this.props.history.push(this.props.universe.getCharactersListUrl(context));
                                this.props.universe.fetch();
                            });
                        }
                    }}>{gettext('Delete')}</button>
                }
                <button type="submit" className="btn btn-primary" disabled={disabled}>{gettext('Save')}</button>
            </div>
        </form>;
    }
}

var AttributeView = ({content})=> {
    return <div className="w-100 d-flex align-items-center align-content-between flex-wrap">
        {Object.entries(content).map(([key, value])=> {
            return <div key={key} className="attribute d-flex flex-row">
                <span className="text-truncate flex-fill">{key}</span>
                <strong className="ml-1">{value}</strong>
            </div>;
        })}
    </div>;
};
var AttributeEdit = observer(({content, disabled})=> {
    return <div className="w-100 d-flex align-items-center align-content-between flex-wrap">
        {Object.entries(content).map(([key, value])=> {
            return <div key={key} className="attribute input-group">
                <div className="input-group-prepend w-50">
                    <label className="input-group-text w-100 text-truncate">{key}</label>
                </div>
                <input
                    disabled={disabled}
                    className="form-control text-right"
                    {...linkValue(key, content)}
                    value={value} />
            </div>;
        })}
    </div>;
});

var SkillCollectionView = ({content})=> {
    return <ul className="list-group">
        {content.map((item, index)=> {
            return <li key={index} className="d-flex flex-row list-group-item">
                <span className="flex-fill">{item.name}</span>
                <strong className="text-right">{item.rank}</strong>
            </li>;
        })}
    </ul>;
};
var SkillCollectionEdit = observer(({content, disabled})=> {
    return <div>
        <ul className="list-unstyled">
            {content.map((item, index)=> {
                return <li key={index} className="input-group m-1">
                    <input
                        disabled={disabled}
                        {...linkValue('name', item)}
                        className="form-control w-50" />
                    <input
                        disabled={disabled}
                        {...linkValue('rank', item)}
                        className="form-control text-right" />
                    <span className="input-group-append">
                        <a className="btn btn-secondary" onClick={(evt)=> {
                            evt.preventDefault();
                            content.splice(index, 1);
                        }}>
                            <i className="fa fa-trash" />
                        </a>
                    </span>
                </li>;
            })}
        </ul>
        <div className="text-center">
            <button type="button" className="btn btn-secondary" disabled={disabled} onClick={(evt)=> {
                evt.preventDefault();
                content.push({name: '', rank: 1});
            }}>{gettext('Add skill')}</button>
        </div>
    </div>;
});
var ItemCollectionView = ({content})=> {
    return <ul className="list-group">
        {content.map((item, index)=> {
            return <li key={index} className="list-group-item">
                {item.name}
            </li>;
        })}
    </ul>;
};
var ItemCollectionEdit = observer(({content, disabled})=> {
    return <div>
        <ul className="list-unstyled">
            {content.map((item, index)=> {
                return <li key={index} className="input-group m-1">
                    <input {...linkValue('name', item)}
                        disabled={disabled}
                        className="form-control form-control-sm" />
                    <div className="input-group-append">
                        <a className="btn btn-secondary" onClick={(evt)=> {
                            evt.preventDefault();
                            content.splice(index, 1);
                        }}>
                            <i className="fa fa-trash" />
                        </a>
                    </div>
                </li>;
            })}
        </ul>
        <div className="text-center">
            <button type="button" className="btn btn-secondary" disabled={disabled} onClick={(evt)=> {
                evt.preventDefault();
                content.push({name: ''});
            }}>{gettext('Add item')}</button>
        </div>
    </div>;
});
var SectionView = ({section})=> {
    return <div className="section">
        <h3>{section.title}</h3>
        <table className="table">
            <thead><tr>
                {section.attributes.map((name, index)=> {
                    return <th key={index}>{name}</th>;
                })}
            </tr></thead>

            <tbody>
                {section.items.map((item, index)=> {
                    return <tr key={index}>
                        {section.attributes.map((name, index)=> {
                            return <td key={index}>{item[index]}</td>;
                        })}
                    </tr>;
                })}
                {section.items.length === 0 &&
                    <td colSpan={section.attributes.length} className="text-muted text-center">
                        {gettext('Nothing')}
                    </td>
                }
            </tbody>
        </table>
    </div>;
};
var SectionEdit = observer(({section, disabled})=> {
    return <div className="section">
        <h3>{section.title}</h3>
        <table className="table">
            <thead><tr>
                {section.attributes.map((name, index)=> {
                    return <th key={index}>{name}</th>;
                })}
                <th></th>
            </tr></thead>

            <tbody>
                {section.items.map((item, index)=> {
                    return <tr key={index}>
                        {section.attributes.map((name, index)=> {
                            return <td key={index}>
                                <input
                                    disabled={disabled}
                                    className="form-control"
                                    onChange={(evt)=> {
                                        item.splice(index, 1, evt.target.value);
                                    }}
                                    value={item[index]} />
                            </td>;
                        })}
                        <td style={{width: 61}}>
                            <button
                                disabled={disabled}
                                type="button"
                                className="btn btn-secondary"
                                onClick={(evt)=> {
                                    evt.preventDefault();
                                    section.items.splice(index, 1);
                                }}>
                                <i className="fa fa-trash" aria-label={gettext('Remove')}></i>
                            </button>
                        </td>
                    </tr>;
                })}
            </tbody>
        </table>
        <button
            type="button"
            className="btn btn-secondary"
            disabled={disabled}
            onClick={(evt)=> {
                evt.preventDefault();
                section.items.push(Array(section.attributes.length).fill(''));
            }}>
            {gettext('Add')}
        </button>
    </div>;
});

export var CharacterSheet = ({story, compact})=> {
    if (!story.attrs.content) {
        return null;
    }
    var content = JSON.parse(story.attrs.content);
    return <div id={story.id} className="character">
        <div className="d-flex">
            <div className="presentation">
                <div className="d-flex d-flex flex-column flex-sm-row">
                    {story.attrs.picture_url &&
                        <div className="character-picture my-2 mr-3 text-center">
                            <img src={story.attrs.picture_url}
                                className="m-0 img-fluid img-thumbnail" />
                        </div>
                    }
                    <div className="my-2 flex-fill">
                        <h2>{story.attrs.title}</h2>
                        {story.attrs.introduction &&
                            <blockquote className="blockquote">
                                <MarkdownView content={story.attrs.introduction} />
                            </blockquote>
                        }
                    </div>
                </div>
                <div className="section attribute-container">
                    <AttributeView content={content.attributes} />
                </div>
                {!compact &&
                    <div className="section attribute-container state">
                        <AttributeView content={content.state} />
                    </div>
                }
                {!compact && content.sections && content.sections.map((section, index)=> {
                    return <SectionView key={index} section={section} />;
                })}
            </div>
            {!compact && (!content.layout || content.layout.skillEnabled) &&
                <div className="skills">
                    <h2>{(content.layout && content.layout.skillLabel) || gettext('Skills')}</h2>
                    <SkillCollectionView content={content.skills} />
                </div>
            }
        </div>
        {!compact && (!content.layout || content.layout.inventoryEnabled) &&
            <div className="inventory">
                <h2>{(content.layout && content.layout.inventoryLabel) || gettext('Inventory')}</h2>
                <ItemCollectionView content={content.items} />
            </div>
        }
    </div>;
};

@observer export class CharacterEditor extends React.Component {
    @observable selectPicture = false;
    content;

    constructor(props) {
        super();
        this.content = observable(JSON.parse(props.story.attrs.content));
    }
    UNSAFE_componentWillReceiveProps(props) {
        if (JSON.stringify(this.content, null, 2) !== props.story.attrs.content) {
            this.content = observable(JSON.parse(props.story.attrs.content));
        }
    }
    render() {
        var disabled = this.props.disabled;

        if (!this.content) {
            return null;
        }
        return <form onSubmit={(evt)=> {
            evt.preventDefault();
            this.props.story.attrs.content = JSON.stringify(this.content, null, 2);
            this.props.onSubmit();
        }}>
            {this.props.universe.attrs.character_sheet_id == this.props.story.id &&
                <div className="form-group">
                    {gettext('This is your basic character sheet, you are editing default values for new characters.')}
                </div>
            }
            {this.props.story.errorMessage() && <p className="text-danger">{this.props.story.errorMessage()}</p>}
            <div className="character">
                <div className="d-flex">
                    <div className="presentation">
                        <div className="d-flex flex-column flex-sm-row">
                            <div className={'character-picture my-2 mr-sm-3 form-group ' +
                                    `text-center ${this.props.story.errorClass('picture_id')}`}>
                                {this.props.story.attrs.picture_url &&
                                    <img
                                        src={this.props.story.attrs.picture_url}
                                        className="img-fluid img-thumbnail m-0 mb-2" />
                                }
                                <button type="button" disabled={disabled} className="btn btn-block btn-secondary" onClick={(evt)=> {
                                    evt.preventDefault();
                                    this.selectPicture = true;
                                }}>{gettext('Select a picture')}</button>
                            </div>

                            <div className="my-2 flex-fill">
                                <div className="form-group">
                                    <input
                                        disabled={disabled}
                                        className={`form-control h2 ${this.props.story.errorClass('title')}`}
                                        placeholder="name"
                                        {...linkValue('title', this.props.story.attrs)} />
                                </div>
                                <div className="form-group">
                                    <textarea
                                        disabled={disabled}
                                        className="form-control h-100"
                                        placeholder="introduction..."
                                        rows={3}
                                        {...linkValue('introduction', this.props.story.attrs)} />
                                </div>
                            </div>
                        </div>

                        <div className="section attribute-container">
                            <AttributeEdit disabled={disabled} content={this.content.attributes} />
                        </div>

                        <div className="section attribute-container state">
                            <AttributeEdit disabled={disabled} content={this.content.state} />
                        </div>
                        {this.content.sections && this.content.sections.map((section, index)=> {
                            return <div key={index} className="section">
                                <SectionEdit disabled={disabled} section={section} />
                            </div>;
                        })}
                    </div>
                    {(!this.content.layout || this.content.layout.skillEnabled) &&
                        <div className="skills">
                            <h2>{(this.content.layout && this.content.layout.skillLabel) || gettext('Skills')}</h2>
                            <SkillCollectionEdit disabled={disabled} content={this.content.skills} />
                        </div>
                    }
                </div>
                {(!this.content.layout || this.content.layout.inventoryEnabled) &&
                    <div className="inventory">
                        <h2>{(this.content.layout && this.content.layout.inventoryLabel) || gettext('Inventory')}</h2>
                        <ItemCollectionEdit disabled={disabled} content={this.content.items} />
                    </div>
                }
            </div>
            {this.props.staticActionBtn ?
                <div>
                    <button type="submit" disabled={disabled} className="btn btn-primary mr-2">{gettext('Save')}</button>
                </div>
                :
                <div className="fixed-bottom no-pointer-events p-3">
                    {!this.props.story.isNew() &&
                        <button className="btn btn-danger pull-right" disabled={disabled} onClick={(evt)=> {
                            evt.preventDefault();
                            this.props.onDelete();
                        }}>{gettext('Delete')}</button>
                    }
                    <button type="submit" disabled={disabled} className="btn btn-primary mr-2">{gettext('Save')}</button>
                </div>
            }
            {/*
            <textarea
                rows={40}
                {...linkValue('content', this.props.story.attrs)}
                className="form-control my-4"></textarea>
            */}
            <Modal open={this.selectPicture} onDismiss={()=> {
                this.selectPicture = false;
            }}>
                <PictureList
                    pictures={this.props.pictures}
                    universe={this.props.universe}
                    onPictureSelected={(picture)=> {
                        this.props.story.attrs.picture_id = picture.id;
                        this.props.story.attrs.picture_url = picture.attrs.url;
                        this.selectPicture = false;
                    }} />
            </Modal>
        </form>;
    }
}

@observer export class CharacterSelectView extends React.Component {

    render() {
        return <div className={`${this.props.characters.state}`}>
            {this.props.onCharacterEdit &&
                <div className="text-center mb-2">
                    <button className="btn btn-primary" onClick={(evt)=> {
                        evt.preventDefault();
                        var character = this.props.characters.add({
                            content_type: 'character',
                            universe_id: this.props.universe.id
                        });

                        var characterSheet = this.props.characters.models.find(
                            (c)=> c.id === this.props.universe.attrs.character_sheet_id
                        );
                        characterSheet.fetch().then(()=> {
                            character.parse(characterSheet.json());
                            delete character.attrs.id;
                            character.attrs.title = '';

                            // decrement order because character sheet is not part of order
                            character.attrs.order = this.props.characters.models.length - 1;

                            this.props.onCharacterEdit(character);
                        });

                    }}>{gettext('Create a Character')}</button>
                </div>
            }
            <CharacterList
                context={this.props.context}
                stories={this.props.characters}
                universe={this.props.universe}
                showCharacterSheet={false}
                onSelectCharacter={(character)=> {
                    character.fetch().then(()=> {
                        this.props.onCharacterSelected(character);
                    });
                }}
                {...(this.props.onCharacterEdit ? {
                    onCharacterEdit: (character)=> {
                        if (!character.isNew()) {
                            character.fetch().then(()=> {
                                this.props.onCharacterEdit(character);
                            });
                        } else {
                            this.props.onCharacterEdit(character);
                        }
                    }
                }: {})} />
        </div>;
    }
}

var CharacterListItem = withRouter(({
    character, isOwner, history, context, isCharacterSheet,
    onMoveUp, onMoveDown, onCharacterEdit, onSelectCharacter
})=> {
    var url = character.getViewUrl(context);
    return <a href={url}
        className="card card-action card-body mb-2"
        onClick={(evt)=> {
            if(!(evt.metaKey || evt.altKey || evt.ctrlKey || evt.shiftKey)) {
                // prevent default only if no modifier key has been pressed
                evt.preventDefault();
                if (onSelectCharacter) {
                    onSelectCharacter(character);
                } else {
                    history.push(url);
                }
            }
        }}>
        <div>
            <div className="pull-right d-flex flex-column">
                {onCharacterEdit &&
                    <button  className="btn btn-secondary ml-2 mb-2"
                        onClick={(evt)=> {
                            evt.stopPropagation();
                            evt.preventDefault();
                            onCharacterEdit(character);
                        }}>{gettext('Edit')}</button>
                }
                {onCharacterEdit &&
                    <button  className="btn btn-secondary ml-2 mb-2"
                        onClick={(evt)=> {
                            evt.stopPropagation();
                            evt.preventDefault();
                            character.fetch().then(()=> {
                                var data = character.json();
                                delete data.id;
                                var newStory = this.props.stories.add(data);
                                newStory.attrs.order = this.props.stories.models.length;
                                this.props.onCharacterEdit(newStory);
                            });
                        }}>{gettext('Duplicate')}</button>
                }
            </div>
            {character.attrs.picture_url &&
                <img src={character.attrs.picture_url}
                    className="img-fluid ml-3 mb-2 pull-right"
                    style={{ maxHeight: 200 }} />
            }
            {character.attrs.private &&
                <em className="pull-right">({gettext('private')})</em>
            }
            {isCharacterSheet &&
                <em className="pull-right">({gettext('Character sheet')})</em>
            }
            <div>
                {isOwner &&
                    <div className="pull-left mr-3">
                        {onMoveUp &&
                            <div onClick={onMoveUp}>
                                <i className="fa fa-caret-up" />
                            </div>
                        }
                        {onMoveDown &&
                            <div onClick={onMoveDown}>
                                <i className="fa fa-caret-down" />
                            </div>
                        }
                        {context.debug &&
                            <div>{character.attrs.order}</div>
                        }
                    </div>
                }
                <h2 className="my-3">{character.attrs.title}</h2>
                <MarkdownView content={character.attrs.introduction} />
            </div>
        </div>
    </a>;
});

@withRouter @observer export class CharacterList extends React.Component {
    moveUp(story, evt) {
        evt.preventDefault();
        evt.stopPropagation();
        var characters = this.props.stories.models.filter((c)=> c.id !== this.props.universe.attrs.character_sheet_id);

        if (story.attrs.order == 0) {return;}

        characters.splice(characters.indexOf(story), 1);
        characters.splice(story.attrs.order - 1, 0, story);

        characters.forEach((s, index)=> {
            if (s.attrs.order != index) {
                s.attrs.order = index;
                s.save(['order']);
            }
        });
    }
    moveDown(story, evt) {
        evt.preventDefault();
        evt.stopPropagation();
        var characters = this.props.stories.models.filter((c)=> c.id !== this.props.universe.attrs.character_sheet_id);

        characters.splice(characters.indexOf(story), 1);
        characters.splice(story.attrs.order + 1, 0, story);

        characters.forEach((s, index)=> {
            if (s.attrs.order != index) {
                s.attrs.order = index;
                s.save(['order']);
            }
        });
    }
    render() {
        var context = this.props.context;
        var isOwner = context.auth.user && this.props.universe.attrs.owner_id === context.auth.user.id;
        var characterSheet = this.props.stories.models.find((c)=> c.id === this.props.universe.attrs.character_sheet_id);

        return <div>
            {!this.props.stories.models.length &&
                this.props.stories.state != 'request' &&
                <div className="text-center">
                    <span className="text-muted">{gettext('There is no characters here yet...')}</span>
                    {context.auth.user && this.props.universe.attrs.owner_id == context.auth.user.id &&
                        <div className="my-3">
                            <Link
                                className="btn btn-primary"
                                to={`${this.props.universe.getViewUrl(context)}/characters/sheet`}>
                                {gettext('Create your Character Sheet')}
                            </Link>
                        </div>
                    }
                </div>
            }
            {this.props.stories.models
                .filter((c)=> !characterSheet || c.id !== characterSheet.id)
                .sort((a, b)=> a.attrs.order - b.attrs.order).map((character, index)=> {
                    return <CharacterListItem
                        character={character}
                        context={context}
                        isOwner={isOwner}
                        onSelectCharacter={this.props.onSelectCharacter}
                        onCharacterEdit={this.props.onCharacterEdit}
                        onMoveUp={this.moveUp.bind(this, character)}
                        onMoveDown={this.moveDown.bind(this, character)}
                        key={character.id || ('c' + index)} />;
                })
            }
            {this.props.showCharacterSheet && characterSheet &&
                <div>
                    <hr />
                    <CharacterListItem
                        character={characterSheet}
                        context={context}
                        isCharacterSheet={true}
                        isOwner={isOwner} />
                </div>
            }
        </div>;
    }
}

@observer export class CharacterListView extends React.Component {
    @observable search = '';
    @observable dropdownCreateStoryOpen = false;
    @observable dropdownStoryOptions = false;

    render() {
        var context = this.props.context;
        var universe = this.props.universe;
        var listUrl = universe.getCharactersListUrl(context);

        return <article className={`${this.props.stories.state} py-4 container`}>
            {this.props.stories.models.length > 0 &&
            <form className="row justify-content-end"
                onSubmit={(evt)=> {
                    evt.preventDefault();
                    this.props.stories.filter({
                        universe_id: universe.id,
                        search: this.search
                    });
                }}>
                <div className="col-md-8 mb-4 mt-0">
                    <label className="input-group">
                        <input
                            className="form-control"
                            type="search"
                            placeholder={gettext('Search...')}
                            {...linkValue('search', this)} />
                        <div className="input-group-append">
                            <button className="btn btn-secondary btn-block">
                                <i className="fa fa-search" />
                            </button>
                        </div>
                    </label>
                </div>
                {context.auth.user && universe.attrs.owner_id == context.auth.user.id &&
                    <div className="col-12 offset-sm-6 col-sm-6 col-md-4 mb-4">
                        <div className="btn-group btn-block my-1">
                            {universe.attrs.character_sheet_id ?
                                <Link className="btn btn-primary btn-block"
                                    to={`${listUrl}/new`}>
                                    {gettext('Create a Character')}
                                </Link>
                                :
                                <Link className="btn btn-primary btn-block"
                                    to={`${listUrl}/sheet`}>
                                    {gettext('Create Character Sheet')}
                                </Link>
                            }
                            {universe.attrs.character_sheet_id &&
                                <button
                                    className="btn btn-primary dropdown-toggle"
                                    type="button"
                                    onClick={(evt)=> {
                                        evt.preventDefault();
                                        this.dropdownStoryOptions = !this.dropdownStoryOptions;
                                    }}>
                                </button>
                            }
                            {universe.attrs.character_sheet_id &&
                                <div className={`dropdown-menu dropdown-menu-right ${this.dropdownStoryOptions ? 'show' : ''}`}>
                                    <Link className="dropdown-item"
                                        to={`${listUrl}/new`}>
                                        {gettext('Create a Character')}
                                    </Link>
                                    <Link className="dropdown-item"
                                        to={`${listUrl}/${universe.attrs.character_sheet_id}/settings`}>
                                        {gettext('Edit Character Sheet')}
                                    </Link>
                                </div>
                            }
                        </div>
                    </div>
                }
            </form>
            }
            <CharacterList
                stories={this.props.stories}
                universe={this.props.universe}
                showCharacterSheet={true}
                context={context} />
        </article>;
    }
}
