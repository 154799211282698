
import {StoryModel, StoryCollection} from './Story';
import {gettext} from '../i18n';


var layout = {
    attributes: {
        'Strength': '',
        'Perception': '',
        'Endurance': '',
        'Will': '',
        'Dexterity': '',
        'Intelligence': '',
        'Speed': '',
        'Charisma': ''
    },
    state: {
        'Actions': '',
        'Initiative': '',
        'Armor': '',
        'Health': ''
    },
    skills: [],
    items: []
};

export class CharacterModel extends StoryModel {
    setInitialLayout() {
        var content = Object.assign({}, layout);
        content.attributes = Object.keys(content.attributes).reduce(function(attributes, key) {
            attributes[gettext(key)] = content.attributes[key];
            return attributes;
        }, {});
        content.state = Object.keys(content.state).reduce(function(state, key) {
            state[gettext(key)] = content.state[key];
            return state;
        }, {});
        this.attrs.content = JSON.stringify(content);
    }
    getViewUrl(context) {
        return `/${context.currentLanguage}/universe/${this.attrs.universe_id}/characters/${this.id}`;
    }
}

export class CharacterCollection extends StoryCollection {
    static modelClass = CharacterModel;
    url = '/api/stories';
    fetch() {
        return this.filter({content_type: 'character'});
    }
    filter(query) {
        return super.filter(Object.assign({content_type: 'character'}, query));
    }
}
