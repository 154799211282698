

export function linkValue(name, model) {
    return {
        value: model[name] || '',
        onChange: (evt)=> {
            model[name] = evt.target.value;
        }
    };
}

export function linkHtml(name, model) {
    return {
        value: model[name] || '',
        onChange: (html)=> {
            model[name] = html;
        }
    };
}

export function linkSelected(name, model, value) {
    return {
        name: name,
        value: value,
        checked: model[name] == value,
        onChange: (evt)=> {
            if (evt.target.checked) {
                model[name] = value;
            }
        }
    };
}
export function linkChecked(name, model) {
    return {
        name: name,
        checked: model[name] || false,
        onChange: (evt)=> {
            model[name] = evt.target.checked;
        }
    };
}

export function updateMetaData(context) {
    if (typeof(document) !== 'undefined' && document.querySelector) {
        document.title = context.title + ' | Multiversalis';
        var descNode = document.querySelector('meta[name=description]');
        if (descNode) {
            descNode.content = context.description;
        }
    }
}
