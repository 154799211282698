
import {observable} from 'mobx';

import {RestApiBackend} from './Backend';


export default class Collection {
    @observable state = '';
    @observable models = [];

    constructor(context, data) {
        this.context = context;
        // if (context instanceof Context) {
        //     throw new Error('First argument must be a context instance');
        // }
        this.api = new RestApiBackend(context);
        this.parse(data);
    }

    initFromContext(name) {
        if (this.context.params[name]) {
            this.parse(this.context.params[name]);
            delete this.context.params[name];
            return true;
        }
    }

    parse(data) {
        this.state = '';
        this.models.clear();
        for (var index in data) {
            this.add(data[index]);
            // this.models[index] = new this.modelClass(data[index]);
        }
    }

    json() {
        return this.models.map((model)=> {
            return model.json();
        });
    }

    add(data) {
        var model = new this.constructor.modelClass(this.context, data);
        this.models.push(model);
        return model;
    }
    prepend(data) {
        var model = new this.constructor.modelClass(this.context, data);
        this.models.unshift(model);
        return model;
    }

    remove(model) {
        this.models.remove(model);
    }

    handleError(err) {
        this.state = 'error';
        console.error(err);
        if (err && err.response) {
            this.errors = err.json;
        }
        throw err;
    }

    filter(query) {
        this.state = 'request';
        this.query = query;
        return this.api.get(this.url, this.query).then((json)=> {
            this.parse(json);
        }).catch((err)=> {
            return this.handleError(err);
        });
    }

    fetch() {
        this.state = 'request';
        this.query = {};
        return this.api.get(this.url).then((json)=> {
            this.parse(json);
        }).catch((err)=> {
            return this.handleError(err);
        });
    }
}
