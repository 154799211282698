
import {observable} from 'mobx';

import Model from './Model';
import {UserModel} from './User';


export class LoginForm extends Model {
    url = '/api/auth/login';
    static schema = {
        name: {type: String},
        email: {type: String},
        password: {type: String},
    };
}
export class RegisterForm extends Model {
    url = '/api/auth/register';
    static schema = {
        name: {type: String},
        email: {type: String},
        password: {type: String},
    };
}
export class ForgotPasswordForm extends Model {
    url = '/api/auth/forgot-password';
    static schema = {
        email: {type: String},
    };
}
export class ChangePasswordForm extends Model {
    url = '/api/auth/change-password';
    static schema = {
        password: {type: String},
        new_password: {type: String},
    };
    save() {
        return super.save().then(()=> {
            this.context.saveToken(this.attrs.token);
            this.context.auth.user = new UserModel(this.context, {
                id: this.id,
                email: this.attrs.email,
                name: this.attrs.name
            });
        });
    }
}
export class ResetPasswordForm extends Model {
    url = '/api/auth/reset-password';
    static schema = {
        password: {type: String},
        token: {type: String},
    };
    save() {
        return super.save().then(()=> {
            this.context.saveToken(this.attrs.token);
            this.context.auth.user = new UserModel(this.context, {
                id: this.id,
                email: this.attrs.email,
                name: this.attrs.name
            });
        });
    }
}

export class Auth extends Model {
    static schema = {
        token: {type: String}
    };
    @observable loginAction;
    @observable user;

    constructor(context) {
        super(context);
        this.loginForm = new LoginForm(context, {});
        this.registerForm = new RegisterForm(context, {});
        this.forgotPasswordForm = new ForgotPasswordForm(context, {});
        this.changePasswordForm = new ChangePasswordForm(context, {});
        this.resetPasswordForm = new ResetPasswordForm(context, {});
    }

    get loggedIn() {
        return !!this.attrs.token;
    }

    status() {
        return this.loginForm.fetch().then(()=> {
            console.log('Backend version', this.loginForm.attrs.app_version);
            if (!this.loginForm.attrs.token) {
                this.context.resetToken();
            }

            this.user = new UserModel(this.context, {
                id: this.loginForm.id,
                email: this.loginForm.attrs.email,
                name: this.loginForm.attrs.name
            });
        }).catch((err)=> {
            console.error(err);
            if (err.status == 401 || err.status == 403) {
                this.context.resetToken();
                this.loginForm.state = '';
            }
        });
    }
    login() {
        return this.loginForm.save().then(()=> {
            this.context.saveToken(this.loginForm.attrs.token);
            this.user = new UserModel(this.context, {
                id: this.loginForm.id,
                email: this.loginForm.attrs.email,
                name: this.loginForm.attrs.name
            });
        }).catch((err)=> {
            console.error(err);
            if (err.status) {
                this.context.resetToken();
            }
        });
    }
    logout() {
        return this.loginForm.delete().then(()=> {
            this.context.resetToken();
        });
    }
    register() {
        return this.registerForm.save().then(()=> {
            this.context.saveToken(this.registerForm.attrs.token);
            this.user = new UserModel(this.context, {
                id: this.registerForm.id,
                email: this.registerForm.attrs.email,
                name: this.registerForm.attrs.name
            });
        }).catch((err)=> {
            console.error(err);
            if (err.status) {
                this.context.resetToken();
            }
        });
    }
    forgotPassword() {
        return this.forgotPasswordForm.save().then(()=> {
            this.loginAction = 'rest-password-sent';
        });
    }
}
