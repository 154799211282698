
import React from 'react';
import {Route, Switch} from 'react-router';
import {observable} from 'mobx';
import {observer} from 'mobx-react';
import {Link, withRouter} from 'react-router-dom';

import {gettext} from '../i18n';
import {StoreObserver} from './helpers/StoreObserver';
import {linkValue, updateMetaData} from './helpers';
import {MarkdownView} from './helpers/Markdown';
import {UniverseModel} from '../models/Universe';
import {PictureCollection} from '../models/Picture';
import {PictureList} from '../views/Picture';
import {Modal} from '../components/Modal';
import {Fade} from '../components/Fade';

import {StoryRouter} from './Story';
import {DiscussionRouter} from './Discussion';
import {CharacterRouter} from './Character';


class UniverseStore {
    @observable universe
    constructor(context, id) {
        this.context = context;
        this.universe = new UniverseModel(context);
        this.universe.id = id;

        if (this.universe.initFromContext('universe')) {
            this.setMetaData(context, this.universe);
        } else {
            this.load();
        }

        if (this.universe.id && context.auth.loggedIn) {
            this.universe.checkStared();
        }
    }
    load() {
        if (this.universe.id) {
            this.universe.fetch().then(()=> {
                this.setMetaData(this.context, this.universe);
            });
        }
    }
    setMetaData(context, universe) {
        context.title = universe.attrs.name;
        context.description = universe.attrs.description || '';
        context.image = universe.attrs.picture_url;
        context.author = universe.attrs.owner_name;
        if (universe.attrs.language && context.currentLanguage != universe.attrs.language) {
            context.changeLanguage(universe.attrs.language);
        }
        updateMetaData(context);
    }
}

class UniverseEditStore {
    constructor(context, id) {
        this.context = context;
        this.universe = new UniverseModel(context);
        this.pictures = new PictureCollection(context);

        if (id) {
            this.universe.id = id;
        }

        if (this.universe.initFromContext('universe')) {
            this.pictures.filter({
                universe_id: id
            });
        } else if (this.universe.id) {
            this.pictures.filter({
                universe_id: id
            });

            this.universe.fetch();
        }
    }
}

@observer class UniverseContainer extends React.Component {
    render() {
        var universe = this.props.universe;

        return <div className={`universe-theme ${universe && universe.attrs.theme}`}>
            {this.props.children}
        </div>;
    }
}

@withRouter @observer class UniverseHeader extends React.Component {
    render() {
        var universe = this.props.universe;
        var context = this.props.context;
        var universeUrl = this.props.universe.getViewUrl(context);
        var path = this.props.history.location.pathname;

        var currentTab;
        var expanded = false;

        if (path.startsWith(universeUrl + '/discussions')) {
            currentTab = 'discussions';
            expanded = path == universeUrl + '/discussions';
        } else if (path.startsWith(universeUrl + '/characters')) {
            currentTab = 'characters';
            expanded = path == universeUrl + '/characters';
        } else {
            currentTab = 'stories';
            expanded = path == universeUrl;
        }

        return <article
            className={`${universe && universe.state} universe-header pt-4 d-flex align-content-end flex-column d-print-none`}>
            <div className="row">
                {universe.attrs.picture_url &&
                    <Fade
                        animation="collapse"
                        open={expanded}
                        className="col-12 col-md-3 text-center">
                        <img
                            alt={universe.attrs.name}
                            src={universe.getResizedPictureUrl('500x700')}
                            className="img-fluid my-2"
                            style={{maxHeight: '200px'}} />
                    </Fade>
                }
                <div className="col">
                    {universe.attrs.name &&
                        (expanded ?
                            <h1 className="mt-0 text-center">{universe.attrs.name}</h1>
                            :
                            <div className="text-center"><strong className="h3">{universe.attrs.name}</strong></div>
                        )
                    }
                    <Fade animation="collapse" open={expanded} className="text-center">
                        <MarkdownView content={universe.attrs.description} />
                    </Fade>
                </div>
                <Fade animation="collapse" open={expanded} className="col-12 col-md-3">
                    <div className="row flex-sm-row-reverse">
                        <div className="col-md-12 col-sm-6">
                            <div className="d-flex flex-column">
                                <div className="input-group my-1">
                                    <div className="input-group-prepend">
                                        <strong className="input-group-text">{universe.attrs.star_count}</strong>
                                    </div>
                                    <div className="input-group-append flex-fill">
                                        <button className="btn btn-secondary btn-block" disabled={universe.stared === null} onClick={()=> {
                                            if (context.auth.loggedIn) {
                                                universe.toggleStar();
                                            } else {
                                                context.auth.loginAction = 'register';
                                            }
                                        }}>
                                            {universe.stared ?
                                                <span><i className="fa fa-star"></i>{' '}{gettext('Unstar')}</span>
                                                :
                                                <span><i className="fa fa-star-o"></i>{' '}{gettext('Star')}</span>
                                            }
                                        </button>
                                    </div>
                                </div>
                                {context.auth.user && universe.attrs.owner_id == context.auth.user.id &&
                                    <Link className="btn btn-secondary btn-block my-1"
                                        to={`${universeUrl}/edit`}>
                                        {gettext('Settings')}
                                    </Link>
                                }
                            </div>
                        </div>
                        <div className="col-md-12 col-sm-6">
                            <div className="text-center my-2">
                                <div>{gettext('Universe Headmaster')}</div>
                                <Link to={`/${context.currentLanguage}/profile/${universe.attrs.owner_id}`}>
                                    <div>
                                        <img src={universe.attrs.owner_avatar_url} style={{maxHeight: 100}} />
                                    </div>
                                    <div>{universe.attrs.owner_name}</div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </Fade>
            </div>
            <ul className="nav nav-tabs mt-auto flex-wrap-reverse align-items-start">
                <li className="nav-item mx-1">
                    <Link to={`${universeUrl}`}
                        className={`nav-link ${currentTab == 'stories' ? 'active' : ''}`}>
                        {gettext('Universe')}
                    </Link>
                </li>
                <li className="nav-item mx-1">
                    <Link to={`${universeUrl}/characters`}
                        className={`nav-link ${currentTab == 'characters' ? 'active' : ''}`}>
                        {gettext('Characters')}
                    </Link>
                </li>
                <li className="nav-item mx-1">
                    <Link to={`${universeUrl}/discussions`}
                        className={`nav-link ${currentTab == 'discussions' ? 'active' : ''}`}>
                        {gettext('Discussions')}
                    </Link>
                </li>
            </ul>
        </article>;
    }
}

export class UniverseRouter extends React.Component {
    render() {
        var url = this.props.match.url;
        var context = this.props.context;

        return <Switch>
            <Route path={`${url}/new`} render={()=> {
                return <StoreObserver store={new UniverseStore(context)} render={({universe})=> {
                    return <UniverseContainer universe={universe}>
                        <UniverseForm context={context} universe={universe} />
                    </UniverseContainer>;
                }} />;
            }} />
            <Route path={`${url}/:id/edit`} render={({match})=> {
                return <StoreObserver
                    store={new UniverseEditStore(context, match.params.id)}
                    render={({universe, pictures})=> {
                        return <UniverseContainer universe={universe}>
                            <UniverseForm
                                context={context}
                                universe={universe}
                                pictures={pictures}
                                onSave={()=> {
                                    this.universeStore.universe && this.universeStore.universe.fetch();
                                }} />
                        </UniverseContainer>;
                    }} />;
            }} />
            <Route path={`${url}/:id`} render={({match})=> {
                var universeId = match.params.id;
                var url = match.url;

                if (!this.universeStore || this.universeStore.universe.id != universeId) {
                    this.universeStore = new UniverseStore(context, universeId);
                }
                // var universe = this.props.universe;

                return <StoreObserver
                    store={this.universeStore}
                    render={({universe})=> {
                        return <UniverseContainer context={context} universe={universe}>
                            <UniverseHeader context={this.props.context} universe={universe} />
                            <Switch>
                                <Route path={`${url}/characters`} render={({match})=> {
                                    return <CharacterRouter match={match} context={context} universe={universe} />;
                                }} />
                                <Route path={`${url}/discussions`} render={({match})=> {
                                    return <DiscussionRouter match={match} context={context} universe={universe} />;
                                }} />
                                <Route path={url} render={({match})=> {
                                    return <StoryRouter match={match} context={context} universe={universe} />;
                                }} />
                            </Switch>
                        </UniverseContainer>;
                    }} />;
            }}>
            </Route>
        </Switch>;
    }
}

@withRouter @observer class UniverseForm extends React.Component {
    @observable addPicture = false;

    render() {
        var context = this.props.context;
        var universe = this.props.universe;
        var disabled = universe && !universe.state && !universe.isOwner(context.auth.user);

        return <form
            className={`pt-3 ${universe.status}`}
            style={{paddingBottom: 70}}
            onSubmit={(evt)=> {
                evt.preventDefault();

                universe.save().then(()=> {
                    if (universe.id) {
                        this.props.history.push(universe.getViewUrl(this.props.context));
                    }
                    this.props.onSave && this.props.onSave();
                });
            }}>
            {universe.errorMessage() && <p className="text-danger">{universe.errorMessage()}</p>}

            {disabled &&
                <p className="text-danger">{gettext('You can not edit this universe! What are you doing here 🤔?')}</p>
            }
            <div className="row">
                {universe.id &&
                    <div className="col-md-3 order-3 order-md-1">
                        <div className={`form-group ${universe.errorClass('picture_id')}`}>
                            {universe.attrs.picture_url &&
                                <div className="text-center mb-3">
                                    <img
                                        src={universe.attrs.picture_url}
                                        className="img-fluid img-thumbnail" />
                                </div>
                            }
                            <button disabled={disabled}
                                className="btn btn-block btn-secondary"
                                type="button"
                                onClick={(evt)=> {
                                    evt.preventDefault();
                                    this.addPicture = true;
                                }}>{gettext('Select a picture')}</button>
                        </div>
                    </div>
                }
                <div className={`order-2 ${universe.id ? 'col-md-9' : 'col-md-12'}`}>
                    <div className="form-group">
                        <input
                            disabled={disabled}
                            className={`form-control h2 ${universe.errorClass('name')}`}
                            placeholder={gettext('Universe name')}
                            required
                            {...linkValue('name', universe.attrs)} />
                    </div>
                    <div className="form-group">
                        <textarea
                            disabled={disabled}
                            placeholder={gettext('Universe presentation...')}
                            rows={10}
                            className={`form-control ${universe.errorClass('description')}`}
                            {...linkValue('description', universe.attrs)} />
                    </div>
                </div>
            </div>
            <div className="form-group">
                <label htmlFor="theme">{gettext('Theme')}</label>
                <select
                    id="theme"
                    disabled={disabled}
                    className={`form-control ${universe.errorClass('theme')}`}
                    required
                    {...linkValue('theme', universe.attrs)}>
                    {Object.entries(UniverseModel.THEMES).map(([name, label])=>
                        <option key={name} value={name}>{gettext(label)}</option>
                    )}
                </select>
            </div>
            <div className="form-group">
                <label htmlFor="theme">{gettext('Language')}</label>
                <select
                    id="language"
                    className={`form-control ${universe.errorClass('language')}`}
                    required
                    disabled={disabled}
                    {...linkValue('language', universe.attrs)}>
                    <option value="en">{gettext('English')}</option>
                    <option value="fr">{gettext('French')}</option>
                </select>
            </div>
            <div className="fixed-bottom no-pointer-events p-3">
                <button type="submit" disabled={disabled} className="btn btn-primary">{gettext('Save')}</button>
                {universe.id &&
                    <button className="btn btn-danger pull-right" disabled={disabled} onClick={(evt)=> {
                        evt.preventDefault();
                        if (confirm(gettext('Are you sure you want to DELETE this universe ?'))) {
                            universe.delete().then(()=> {
                                this.props.history.push('/profile/me');
                            }, (err)=> {
                                if (err && err.json && err.json.global) {
                                    alert(err.json.global);
                                }
                            });
                        }
                    }}>{gettext('Delete')}</button>
                }
            </div>
            <Modal open={this.addPicture} onDismiss={()=> {
                this.addPicture = false;
            }}>
                <PictureList
                    pictures={this.props.pictures}
                    universe={universe}
                    onPictureSelected={(picture)=> {
                        universe.attrs.picture_id = picture.id;
                        universe.attrs.picture_url = picture.attrs.url;
                        this.addPicture = false;
                    }} />
            </Modal>
        </form>;
    }
}

@observer export class UniverseListItem extends React.Component {
    render() {
        var universe = this.props.universe;
        return <Link
            to={`/${universe.attrs.language}/universe/${universe.id}`}
            className={`universe-theme ${universe.attrs.theme} card card-body card-action text-center universe my-4`}>
            <h2>{universe.attrs.name}</h2>
            <div className="universe-star-count text-muted">
                <i className="fa fa-star"></i> {universe.attrs.star_count}
            </div>
            {universe.attrs.picture_url &&
                <div>
                    <img className="img-fluid my-3"
                        alt={universe.attrs.name}
                        src={universe.getResizedPictureUrl('500x700')} />
                </div>
            }
            <div className="description" ref={(c)=> this.descComponent = c}>
                <MarkdownView content={universe.attrs.description} />
            </div>
        </Link>;
    }
}

@observer export class UniverseSelectView extends React.Component {
    render() {
        return <div>
            {this.props.universes.length == 1 &&
                <span className="text-muted">{gettext('You have only one universe...')}</span>
            }
            <div className="list-group">
                {this.props.universes.map((universe)=> {
                    return <div
                        key={universe.id}
                        className="list-group-item list-group-item-action"
                        onClick={(evt)=> {
                            evt.preventDefault();
                            this.props.onSelect(universe);
                        }}>
                        {universe.attrs.name}
                    </div>;
                })}
            </div>
        </div>;
    }
}

@withRouter @observer export class UniverseList extends React.Component {
    @observable nbColumns = 1;
    getNbColumns() {
        if (document.body.clientWidth > 992) {
            return 3;
        } else {
            return 1;
        }
    }
    componentDidMount() {
        if (this.props.context.clientSide) {
            this.resizeNbColumnHandler = ()=> {
                var nbColumns = this.getNbColumns();
                if (this.nbColumns !== nbColumns) {
                    this.nbColumns = nbColumns;
                }
            };
            window.addEventListener('resize', this.resizeNbColumnHandler);
            this.resizeNbColumnHandler();
        }
    }
    componentWillUnmount() {
        if (this.props.context.clientSide) {
            window.removeEventListener('resize', this.resizeNbColumnHandler);
        }
    }
    render() {
        return <div className={`${this.props.universes.state} row`}>
            {Array.from((new Array(this.nbColumns || 1)).keys()).map((colIndex)=> {
                return <div key={colIndex} className={`flex-fill col-${12 / this.nbColumns}`}>
                    {this.props.universes.models.map((universe, index)=> {
                        if (index % this.nbColumns === colIndex) {
                            return <UniverseListItem key={universe.id} universe={universe} />;
                        }
                    })}
                </div>;
            })}
        </div>;
    }
}
