import React from 'react';
import {observer} from 'mobx-react';
import {observable} from 'mobx';


@observer export class Random extends React.Component {
    @observable diceRolls = [];
    @observable nameRolls = [];
    @observable codeRolls = [];
    @observable codePatern = 'AAA-XXX-999';

    render() {
        return <div className="my-3 card card-body">
            <div className="row">
                <div className="col-4">
                    <h2>Roll dice:</h2>
                    <button className="btn btn-secondary mx-1 px-2" onClick={this.rollDice.bind(this, 4)}>D4</button>
                    <button className="btn btn-secondary mx-1 px-2" onClick={this.rollDice.bind(this, 6)}>D6</button>
                    <button className="btn btn-secondary mx-1 px-2" onClick={this.rollDice.bind(this, 8)}>D8</button>
                    <button className="btn btn-secondary mx-1 px-2" onClick={this.rollDice.bind(this, 10)}>D10</button>
                    <button className="btn btn-secondary mx-1 px-2" onClick={this.rollDice.bind(this, 12)}>D12</button>
                    <button className="btn btn-secondary mx-1 px-2" onClick={this.rollDice.bind(this, 20)}>D20</button>
                    <button className="btn btn-secondary mx-1 px-2" onClick={this.rollDice.bind(this, 100)}>D100</button>
                    <div>
                        {this.diceRolls.map((roll, index)=> {
                            return <div key={index}>{roll.value} / {roll.dice}</div>;
                        })}
                    </div>
                </div>

                <div className="col-4">
                    <h2>Roll name:</h2>
                    <button className="btn btn-primary" onClick={this.rollName.bind(this)}>Roll</button>
                    <div>
                        {this.nameRolls.map((roll, index)=> {
                            return <div key={index}>{roll}</div>;
                        })}
                    </div>
                </div>

                <div className="col-4">
                    <h2>Roll code:</h2>
                    <label>pattern: (format: AAA-XXX-999)</label>
                    <input type="name" className="form-control inline" value={this.codePatern} onChange={(evt)=> this.codePatern = evt.target.value} />
                    <button className="btn btn-primary mt-2" onClick={this.rollCode.bind(this)}>Roll</button>
                    <div>
                        {this.codeRolls.map((roll, index)=> {
                            return <div key={index}>{roll}</div>;
                        })}
                    </div>
                </div>
            </div>
        </div>;
    }
    rollDice(dice, evt) {
        evt.preventDefault();
        this.diceRolls.unshift({
            value: Math.ceil(Math.random() * dice),
            dice: dice
        });
    }
    rollName(evt) {
        evt.preventDefault();
        // $.getJSON('//namey.muffinlabs.com/name.json?with_surname=true&frequency=all&callback=?').then((name)=> {
        //     this.nameRolls.unshift(name[0]);
        // });
    }
    rollCode(evt) {
        var alpha = 'abcdefghijklmnopqrstuvwxyz';
        var alphaI = alpha + alpha.toUpperCase();
        var digit = '1234567890';
        var alphaDigit = alpha + digit;
        var alphaIDigit = alphaI + digit;
        evt.preventDefault();
        var value = this.codePatern;
        value = value.replace(/a/g, ()=> alpha[Math.floor(Math.random() * alpha.length)]);
        value = value.replace(/A/g, ()=> alphaI[Math.floor(Math.random() * alphaI.length)]);
        value = value.replace(/9/g, ()=> digit[Math.floor(Math.random() * digit.length)]);
        value = value.replace(/x/g, ()=> alphaDigit[Math.floor(Math.random() * alphaDigit.length)]);
        value = value.replace(/X/g, ()=> alphaIDigit[Math.floor(Math.random() * alphaIDigit.length)]);
        this.codeRolls.unshift(value);
    }
}
