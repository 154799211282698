
import {observable} from 'mobx';

import {RestApiBackend} from './Backend';


export default class Model {
    @observable state = '';
    @observable attrs = {};
    @observable original = {};
    @observable errors = null;

    constructor(context, data) {
        this.context = context;
        this.api = new RestApiBackend(context);
        this.parse(data);
    }

    initFromContext(name) {
        if (this.context.params[name]) {
            this.parse(this.context.params[name]);
            delete this.context.params[name];
            return true;
        }
        if (this.context.serverSide) {
            // avoid server side loading
            if (this.id) {
                this.state = 'request';
            }
            return true;
        }
    }

    json(fields) {
        var data = {};
        if (fields) {
            for (var field of fields) {
                data[field] = this.attrs[field];
            }
            return data;
        }
        return Object.assign(data, this.attrs);
    }

    parse(data) {
        data = this.initAttrs(data);
        this.attrs = observable(data);
        this.state = '';
    }

    get id() {
        return this.attrs.id;
    }

    set id(id) {
        this.attrs.id = id;
    }

    isNew() {
        return !this.id;
    }

    initAttrs(attrs) {
        if (!attrs) {
            attrs = {};
        }

        if (!attrs.id) {
            for (var field in this.constructor.schema) {
                if (!(field in attrs)) {
                    attrs[field] = null;
                }
            }
        }
        return attrs;
    }

    handleError(err) {
        this.state = 'error';
        if (err && err.response && err.json) {
            this.errors = err.json;
            console.error(err, err.json);
        } else {
            console.error(err);
        }
        throw err;
    }

    errorClass(field) {
        if (this.errors && this.errors[field]) {
            return 'is-invalid';
        // } else if (this.attrs[field]) {
        //     return 'has-success';
        }
        return '';
    }
    errorMessage(field) {
        if (field) {
            return (this.errors && this.errors[field]) || '';
        }
        return this.errors && this.errors['global'];
    }

    save(fields) {
        if (this.id) {
            return this.update(fields);
        } else {
            return this.create(fields);
        }
    }

    create(fields) {
        this.state = 'request';
        this.errors = null;
        return this.api.post(this.url, this.json(fields)).then((json)=> {
            this.parse(json);
            return this;
        }).catch((err)=> {
            return this.handleError(err);
        });
    }

    update(fields) {
        this.state = 'request';
        this.errors = null;
        return this.api.patch(this.url, this.json(fields)).then((json)=> {
            this.parse(json);
            return this;
        }).catch((err)=> {
            return this.handleError(err);
        });
    }

    fetch() {
        this.state = 'request';
        this.errors = null;
        return this.api.get(this.url).then((json)=> {
            this.parse(json);
            return this;
        }).catch((err)=> {
            return this.handleError(err);
        });
    }

    delete() {
        this.state = 'request';
        this.errors = null;
        return this.api.delete(this.url).then(()=> {
            this.parse({});
            return this;
        }).catch((err)=> {
            return this.handleError(err);
        });
    }
}
