import {observable} from 'mobx';

import Model from './Model';
import Collection from './Collection';


var THEMES = {
    'classic': 'Neutral',
    'scifi': 'Cyber',
    'medieval': 'Parchemin',
    'horror': 'Horror',
    'bloody': 'Bloody',
};

export class UniverseModel extends Model {
    @observable stared = null;
    static THEMES = THEMES;
    static schema = {
        name: {type: String},
        description: {type: String},
        picture_id: {type: Number},
        picture_url: {type: String},
        theme: {type: String, choices: THEMES},
        owner_id: {type: Number},
        star_count: {type: Number},
        character_sheet_id: {type: Number},
    };

    constructor(context, data) {
        super(context, {
            language: context.currentLanguage,
            theme: 'classic',
            ...data
        });
    }

    get url() {
        if (this.id) {
            return `/api/universes/${this.id}`;
        }
        return '/api/universes';
    }
    checkStared() {
        this.api.get(`${this.url}/star`).then((resp)=> {
            this.stared = Boolean(resp);
        });
    }
    toggleStar() {
        if (this.stared) {
            this.stared = null;
            this.api.delete(`${this.url}/star`).then(()=> {
                this.stared = false;
                this.attrs.star_count--;
            }).catch(console.error);
        } else {
            this.stared = null;
            this.api.post(`${this.url}/star`).then(()=> {
                this.stared = true;
                this.attrs.star_count++;
            }).catch(console.error);
        }
    }
    getViewUrl(context) {
        return `/${context.currentLanguage}/universe/${this.id}`;
    }
    getDiscussionsListUrl(context) {
        return `${this.getViewUrl(context)}/discussions`;
    }
    getCharactersListUrl(context) {
        return `${this.getViewUrl(context)}/characters`;
    }
    getTableOfContentsUrl(context) {
        return `${this.getViewUrl(context)}/table-of-contents`;
    }
    getResizedPictureUrl(size) {
        return this.attrs.picture_path && `//${this.context.backendHost}/api/resize/${size}?path=/${this.attrs.picture_path}`;
    }
    isOwner(user) {
        return (this.isNew() && user && user.id) || (user && this.attrs.owner_id === user.id);
    }
}

export class UniverseCollection extends Collection {
    static modelClass = UniverseModel;
    url = '/api/universes';
}
