
import Model from './Model';
import Collection from './Collection';

export class DiscussionCommentModel extends Model {
    static schema = {
        content: {type: String},
        discussion_id: {type: Number},
    };

    get url() {
        if (this.id) {
            return `/api/discussion-comments/${this.id}`;
        }
        return '/api/discussion-comments';
    }
    getViewUrl(context) {
        return `/${context.currentLanguage}/universe/${this.attrs.universe_id}/discussions/${this.discussion_id}`;
    }
}

export class DiscussionCommentCollection extends Collection {
    static modelClass = DiscussionCommentModel;
    url = '/api/discussion-comments';
}
