
import React from 'react';
import {observer} from 'mobx-react';
import Transition from 'react-transition-group/Transition';


export class Fade extends React.Component {
    render() {

        if (this.props.open && typeof this.props.children === 'function') {
            this.children = this.props.children(status);
        } else if (this.props.open && this.props.children) {
            this.children = this.props.children;
        }
        var className = `${this.props.className || ''} ${this.props.animation || 'fade'}`;

        return <Transition
            in={this.props.open}
            timeout={{
                enter: 0,
                exit: 300,
            }}
            onExit={()=> {
                this.props.onDismiss && this.props.onDismiss();
            }}
            mountOnEnter={true}
            unmountOnExit={true}>
            {(state) => {
                var show = ['entered'].includes(state);
                return <div
                    className={`${className} ${show ? 'show' : ''}`}
                    onClick={this.props.onClick}>
                    {this.children}
                </div>;
            }}
        </Transition>;
    }
}

@observer export class Backdrop extends React.Component {
    render () {
        return <Fade
            className="modal-backdrop"
            onClick={this.props.onClickOutside}
            onDismiss={this.props.onDismiss}
            open={this.props.open}>
            {this.props.children}
        </Fade>;
    }
}
